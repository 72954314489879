import { useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';

interface OutletContextProps {
  subSubKey: string;
}

export function useSubSubMenu() {
  return useOutletContext<OutletContextProps>();
}

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
