import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { imageUrl } from '../../assets/images/urls';
import { isKoreanLanguage } from '../../helpers';

interface StyledBannerInterface {
  imageUrl?: string;
}

export const CeoMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  max-width: 1080px;
  position: relative;

  img {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    img {
      width: 100vw;
      height: 100vw;
      object-fit: cover;
    }

    p {
      img {
        height: unset;
      }
    }
  }
`;

export const CeoMessageContent = styled.article`
  display: flex;
  flex-direction: row;
  padding: 64px 0;
  width: 100%;
  box-sizing: border-box;

  header {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    white-space: pre-line;
    margin-right: 50px;
    min-width: 300px;
  }

  div {
    p {
      white-space: pre-line;
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: -0.02em;
      color: #1a1a1a;
      margin: 0;
    }

    img {
      display: block;
      float: right;
      width: 151px;
      margin-top: 64px;
      height: unset;
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 32px 16px;

    header {
      margin-bottom: 12px;
      min-width: unset;
      margin-right: 0px;
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #1a1a1a;
      white-space: unset;
    }

    div {
      p {
        position: relative;
      }

      img {
        margin-top: 64px;
        margin-left: -16px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    header {
      white-space: pre-line;
    }
  }
`;

const ImageWrapper = styled.div<StyledBannerInterface>`
  width: 100%;
  height: 380px;
  background-size: cover;
  background-position: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  display: flex;

  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;

    letter-spacing: -0.02em;
    color: #ffffff;
  }

  @media screen and (max-width: 992px) {
    header {
      margin: 20px 0px 24px;
    }

    height: 380px;
    object-fit: cover;
  }
`;

const CeoMessage = () => {
  const { t, i18n } = useTranslation();

  return (
    <CeoMessageWrapper>
      <ImageWrapper imageUrl={imageUrl.ceoMessage}>{/* <h1>{t('submenu.ceo-message')}</h1> */}</ImageWrapper>
      <CeoMessageContent>
        <header>{t('company.ceo-message.t1')}</header>
        <div>
          <p>{t('company.ceo-message.p1')}</p>
          {isKoreanLanguage(i18n.language) ? (
            <img src={imageUrl.sampleCeo} alt="ceo" />
          ) : (
            <p style={{ textAlign: 'right' }}>
              <br />
              Sophie Kim
            </p>
          )}
        </div>
      </CeoMessageContent>
    </CeoMessageWrapper>
  );
};

export default CeoMessage;
