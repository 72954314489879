import { useEffect, useMemo, useState } from 'react';
import { dateHelper, isEmptyObject } from '../../helpers';
import ArrowIcon from '../../assets/images/BlackArrow.png';
import StringDropDownItem from '../../components/DropDown/StringDropDown';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface GridListInterface {
  data: Array<TableRowInterface>;
}

interface TableRowInterface {
  id: number;
  title_t: string;
  datetime: string;
  submenu: string;
}

interface SortedDataInterface {
  [year: string]: any; // index signature
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  // content wrapper css start
  width: 100%;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;

    .dropdownWrapper {
      width: 110px;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;

      .dropdownWrapper {
        margin-top: 0px;
        width: 110px;

        .parentWrapper {
          padding: 0px;
        }

        .childrenWrapper {
          top: 0px;
          left: unset;
          right: 0px;
          border-bottom: none;
          background: #f9f8f9;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
          width: 150px;
          z-index: 990;

          & > div {
            margin: 24px 10px;
            color: #1a1a1a;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const EmptyContent = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    height: 64px;
    border-bottom: 1px solid #999999;

    & > * {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .title,
    .datetime {
      flex: 1;
    }

    .datetime {
      // color: #999999;
    }

    img {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;

    .datetime {
      color: #1a1a1a;
    }
  }

  @media screen and (max-width: 1080px) {
    .row {
      height: unset;
      padding: 32px 0px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .title {
        margin: 0px 0px 16px 0px;
        text-align: left;
      }

      .datetime {
        margin: 0;
        order: 2;
        color: #999999;
      }

      img {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        right: 0px;
        top: calc(50% - 12px);
      }
    }

    .row.header {
      display: none;
    }
  }
`;

const NewsRoomBoard = ({ data }: GridListInterface) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sortedData, setSortedData] = useState<SortedDataInterface>({});
  const [selectedYear, setSelectedYear] = useState('');

  const scheduledYears = useMemo<string[]>(() => Object.keys(sortedData).reverse(), [sortedData]);

  useEffect(() => {
    setSelectedYear('');

    const languageSortedData = {};
    if (data.length === 0 || !isEmptyObject(languageSortedData)) return;

    let _sortedData: SortedDataInterface = {};
    data.forEach(async (item: TableRowInterface) => {
      if (item.submenu !== 'notice') return;
      const year = new Date(item.datetime).getFullYear().toString();
      _sortedData = Object.assign(languageSortedData, { [year]: [...(_sortedData[year] || []), item] });
    });

    setSortedData({ ..._sortedData });
  }, [data]);

  useEffect(() => {
    if (scheduledYears.length === 0 || selectedYear) return;
    setSelectedYear(scheduledYears[0]);
  }, [scheduledYears]);

  const handleClickRow = (id: number) => {
    navigate(`/media/news-room/notice/${id}`);
  };

  const handleClickDropdown = (value: string) => {
    setSelectedYear(value);
  };

  return (
    <Wrapper>
      <header>
        <div className="dropdownWrapper">
          <StringDropDownItem name={`${selectedYear}${t('year')}`} children={scheduledYears} handleClick={handleClickDropdown} />
        </div>
      </header>
      <TableSection>
        <div className="row header">
          <span className="datetime">{`${t('date2')}`}</span>
          <span className="title">{`${t('title1')}`}</span>
        </div>
        {sortedData[selectedYear] ? (
          sortedData[selectedYear].map((item: TableRowInterface, index: number) => (
            <div className="row" key={index} onClick={() => handleClickRow(item.id)}>
              <span className="datetime">{dateHelper(item.datetime, '-')}</span>
              <span className="title">{item.title_t}</span>
              <img src={ArrowIcon} alt="arrow" />
            </div>
          ))
        ) : (
          <EmptyContent>게시글이 없습니다.</EmptyContent>
        )}
      </TableSection>
    </Wrapper>
  );
};

export default NewsRoomBoard;
