import { Outlet, NavLink, useLocation, Navigate, useNavigate, useParams } from 'react-router-dom';
import { EN_HIDDEN_LIST, INACTIVE_LIST, IS_NEWS_URL, MENU } from '../../helpers/constants';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DropDownItem from '../DropDown';

interface SubMenuInterface {
  name: string;
  to: string;
}

interface SubSubMenuInterface {
  name: string;
  key: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PcSubMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const MobileSubMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  @media screen and (min-width: 993px) {
    display: none;
  }
`;

const SubSubMenuWrapper = styled(PcSubMenuWrapper)`
  display: flex;
  flex-direction: row;
  min-width: 768px;
  box-sizing: border-box;

  .subSubItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #93928e;
    padding: 0px 0px 16px;
    border-bottom: 1px solid #cecdca;

    &:hover {
      cursor: pointer;
    }
  }

  .subSubEngItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #93928e;
    padding: 0px 0px 16px;
    border-bottom: 1px solid #cecdca;

    &:hover {
      cursor: pointer;
    }
  }

  .subSubItem.active {
    color: #5f0080;
    border-bottom: 1px solid #5f0080;
  }

  .subSubEngItem.active {
    color: #5f0080;
    border-bottom: 1px solid #5f0080;
  }

  .subSubItem.inactive {
    color: #d3cedd !important;
    pointer-events: none !important;
  }

  .subSubEngItem.inactive {
    color: #d3cedd !important;
    pointer-events: none !important;
  }

  // subsub menu (width: 768px) breakpoint
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    min-width: unset;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0px;
    justify-content: unset;
    overflow-x: scroll;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      display: none;
    }

    .subSubItem {
      text-align: center;
      padding: 0px 0px 14px;
    }

    .subSubEngItem {
      padding: 0px 8px 14px;
    }
  }
`;

const SubMenuLink = styled(NavLink)`
  text-decoration: none;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #999999;
  position: relative;
  margin: 0px 32px;

  &::after {
    content: '|';
    position: absolute;
    margin-left: 32px;
    color: #c4c4c4;
    font-size: 12px;
  }

  &:last-child::after {
    content: '';
  }

  &.active {
    color: #5f0080;
  }
`;

const SubNav = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { pathname = '' } = location;

  const firstPathName = IS_NEWS_URL ? 'ir' : pathname.split('/')[1];
  const secondPathName = IS_NEWS_URL ? 'report' : pathname.split('/')[2];
  const thirdPathName = pathname.split('/')[3];

  const subSubMenuList =
    (secondPathName && MENU[firstPathName].subMenuList.find((i: SubMenuInterface) => i.to === secondPathName).subSubMenuList) || [];

  return secondPathName ? (
    <Wrapper>
      <PcSubMenuWrapper>
        {MENU[firstPathName].subMenuList.map(
          (item: SubMenuInterface, index: number) =>
            !(i18n.language === 'en' && EN_HIDDEN_LIST.includes(item.to)) && (
              <SubMenuLink
                to={IS_NEWS_URL ? '/redirect' : item.to}
                key={index}
                id={`${item.to}-${i18n.language}`}
                className={`${INACTIVE_LIST.includes(item.to) ? 'inactive' : ''} ${item.to === secondPathName ? 'active' : ''}`}
                state={{ path: firstPathName + '/' + item.to }}
              >
                {t(`submenu.${item.to}`)}
              </SubMenuLink>
            )
        )}
      </PcSubMenuWrapper>
      <MobileSubMenuWrapper>
        <DropDownItem
          name={t(`submenu.${MENU[firstPathName].subMenuList.find((subMenu: SubMenuInterface) => subMenu.to === secondPathName)['to']}`)}
          children={MENU[firstPathName].subMenuList}
          to={`/${firstPathName}`}
          isSubMenu={true}
        />
      </MobileSubMenuWrapper>
      {subSubMenuList.length > 0 ? (
        <SubSubMenuWrapper>
          {subSubMenuList.map((item: SubSubMenuInterface, index: number) => (
            <div
              key={index}
              onClick={() => navigate(`/${firstPathName}/${secondPathName}/${item.key}`)}
              className={`${i18n.language === 'en' ? 'subSubEngItem' : 'subSubItem'} ${item.key === thirdPathName ? 'active' : ''} ${
                INACTIVE_LIST.includes(item.key) ? 'inactive' : ''
              }`}
            >
              {t(`subsubmenu.${item.key}`)}
            </div>
          ))}
        </SubSubMenuWrapper>
      ) : (
        <></>
      )}
      <Outlet context={{ subSubKey: thirdPathName }} />
    </Wrapper>
  ) : IS_NEWS_URL ? (
    <Navigate to="/redirect" state={{ path: MENU[firstPathName].subMenuList[0].to }} />
  ) : (
    <Navigate to={MENU[firstPathName].subMenuList[0].to} />
  );
};

export default SubNav;
