import styled from 'styled-components';
import LeftButton from '../../assets/images/LeftArrow.svg';
import RightButton from '../../assets/images/RightArrow.svg';
import RightRoundButton from '../../assets/images/RoundRightArrow.svg';
import LeftRoundButton from '../../assets/images/RoundLeftArrow.svg';

interface PaginationBarProps {
  clickPageHandler?: any;
  clickNext?: any;
  clickPrev?: any;
  marginTop?: any;
  pageTotal?: any;
  currentPage?: any;
}

const Wrapper = styled.div`
  margin-top: 64px;
  align-self: center;
  height: 32px;
  display: flex;

  .left,
  .right {
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }

  .button,
  .clickedButton {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: #ffffff;
    border: none;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #1a1a1a;
    }
  }

  & > :not(:last-child) {
    margin-right: 24px;
  }

  .clickedButton {
    background: #5f0080;
    span {
      color: #ffffff;
    }
  }

  @media screen and (max-width: 992px) {
    & > :not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const PaginationBar = ({ clickPageHandler, pageTotal, currentPage, clickNext, clickPrev }: PaginationBarProps) => (
  <Wrapper>
    <div className="left" onClick={clickPrev}>
      <img src={LeftRoundButton} alt="LeftButton" />
    </div>
    {[...Array(pageTotal)].map((n, index) => {
      const pagenum = index + 1;
      return (
        <button className={currentPage === pagenum ? 'clickedButton' : 'button'} onClick={() => clickPageHandler(pagenum)} key={index}>
          <span>{pagenum}</span>
        </button>
      );
    })}
    <div className="right" onClick={clickNext}>
      <img src={RightRoundButton} alt="RightButton" />
    </div>
  </Wrapper>
);

export default PaginationBar;
