const fileBucketNameTemp = '/api'; //process.env.REACT_APP_API_URL;
const fileBucketName = '/api'; //process.env.REACT_APP_API_URL;

interface ImageUrlProps {
  [prop: string]: string;
}

// TODO: 여기에 있는 파일들을 추후 생성할 s3 파일저장소 버킷에 마이그레이션 합니다
const imageUrl: ImageUrlProps = {
  mainBanner1: fileBucketNameTemp + '/images/main_banner_1_3.jpg',
  mainBanner2: fileBucketNameTemp + '/images/main_banner_2_2.jpg',
  mainBanner3: fileBucketNameTemp + '/images/main_banner_3_3.jpg',
  sample1: fileBucketNameTemp + '/images/smaple1.png',
  sample2: fileBucketNameTemp + '/images/sample2.png',
  sampleCeo: fileBucketNameTemp + '/images/ceo-message-sign.png',
  ownerStructurePc: fileBucketNameTemp + '/images/joojooPc.png',
  ownerStructureMobile: fileBucketNameTemp + '/images/joojooMobile.png',
  mainPartnership: fileBucketNameTemp + '/images/mainPartnership2_2.jpg',
  mainEnvironment: fileBucketNameTemp + '/images/environmentBanner.jpg',
  mainSocial: fileBucketNameTemp + '/images/socialBanner.jpg',
  mainBanner: fileBucketName + '/assets/mainbanner.png',
  mediaBanner: fileBucketNameTemp + '/images/media_banner_new_2.jpg',
  irBanner: fileBucketNameTemp + '/images/ir_banner_new_2.jpg',
  financeBanner: fileBucketNameTemp + '/images/finance_banner_new_2.jpg',
  companyBanner: fileBucketNameTemp + '/images/company_banner_kurly.jpg',
  stockBanner: fileBucketNameTemp + '/images/stockbanner.jpg',
  overview1Pc: fileBucketNameTemp + '/images/overview1_new_pc.jpg',
  overview1Mobile: fileBucketNameTemp + '/images/overview1Mobile_2.png',
  overview2Pc: fileBucketNameTemp + '/images/overview2Pc_2.png',
  overview2Mobile: fileBucketNameTemp + '/images/overview2Mobile_2.png',
  overviewIcon1: fileBucketNameTemp + '/images/overviewIcon1.png',
  overviewIcon2: fileBucketNameTemp + '/images/overviewIcon2.png',
  overviewIcon3: fileBucketNameTemp + '/images/overviewIcon3.png',
  environmentBanner: fileBucketNameTemp + '/images/environmentBanner_2.jpg',
  environment1: fileBucketNameTemp + '/images/environment1_2.png',
  environment2: fileBucketNameTemp + '/images/environment2_2.png',
  environment3: fileBucketNameTemp + '/images/environment3_2.png',
  environment4: fileBucketNameTemp + '/images/environment4_2.png',
  partnershipBanner: fileBucketNameTemp + '/images/partnershipBanner.png',
  partnership1: fileBucketNameTemp + '/images/partnership1_3.jpg',
  partnership2: fileBucketNameTemp + '/images/partnership2.png',
  partnership3: fileBucketNameTemp + '/images/partnership3.png',
  partnership4: fileBucketNameTemp + '/images/partnership4.png',
  socialBanner: fileBucketNameTemp + '/images/socialBanner_2.png',
  social1: fileBucketNameTemp + '/images/social1_2.png',
  social2: fileBucketNameTemp + '/images/social2_2.png',
  social3: fileBucketNameTemp + '/images/social3_2.png',
  social4: fileBucketNameTemp + '/images/social4.jpg',
  social5: fileBucketNameTemp + '/images/social5.jpg',
  social6: fileBucketNameTemp + '/images/social6_2.png',
  introduction1: fileBucketNameTemp + '/images/introduction_truck.jpg',
  introduction2: fileBucketNameTemp + '/images/introduction_food.jpg',
  ceoMessage: fileBucketNameTemp + '/images/ceomessage.jpg'
};

const imageUrlEng: ImageUrlProps = {
  ownerStructurePc: fileBucketNameTemp + '/images/en/joojooPc_2.png',
  ownerStructureMobile: fileBucketNameTemp + '/images/en/joojooMobile_2.png',
  overview1Pc: fileBucketNameTemp + '/images/en/overview1_new_pc_en.jpg',
  overview1Mobile: fileBucketNameTemp + '/images/en/overview1Mobile_2.png',
  overview2Pc: fileBucketNameTemp + '/images/en/overview2Pc_3.png',
  overview2Mobile: fileBucketNameTemp + '/images/en/overview2Mobile_2.png'
};

export { imageUrl, imageUrlEng };
