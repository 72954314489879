import { useMemo } from 'react';
import styled from 'styled-components';
import { useSubSubMenu } from '../../context/router';
import { priceHelper } from '../../helpers';
import { useTranslation } from 'react-i18next';

interface SummaryInterface {
  [key: string]: any; // index signature
}

interface TableRowInterface {
  year: number;
}

const IS_COLUMNS = ['매출액', '매출총이익', '영업이익', '법인세비용차감전이익', '당기순이익'];
const BS_COLUMNS = ['유동자산', '비유동자산', '자산총계', '유동부채', '비유동부채', '부채총계', '자본총계'];
const BS_INACTIVE_COLUMNS = ['자산총계', '부채총계', '자본총계'];

const SUMMARY: SummaryInterface = {
  // Consolidated 연결재무제표
  consolidated: {
    // Income Statement 손익계산서
    is: [
      { year: 2020, 매출액: 9531, 매출총이익: 1693, 영업이익: -1163, 법인세비용차감전이익: -2224, 당기순이익: -2224 },
      { year: 2021, 매출액: 15614, 매출총이익: 2936, 영업이익: -2177, 법인세비용차감전이익: -12853, 당기순이익: -12853 },
      { year: 2022, 매출액: 20372, 매출총이익: 5616, 영업이익: -2335, 법인세비용차감전이익: -2232, 당기순이익: -2232 }
    ],
    // Balance Sheet 재무상태표
    bs: [
      {
        year: 2020,
        유동자산: 2231,
        비유동자산: 3639,
        자산총계: 5871,
        유동부채: 8534,
        비유동부채: 2656,
        부채총계: 11190,
        자본총계: -5319
      },
      {
        year: 2021,
        유동자산: 2773,
        비유동자산: 4171,
        자산총계: 6944,
        유동부채: 2768,
        비유동부채: 3131,
        부채총계: 5900,
        자본총계: 1045
      },
      {
        year: 2022,
        유동자산: 2939,
        비유동자산: 4764,
        자산총계: 7703,
        유동부채: 2612,
        비유동부채: 3187,
        부채총계: 5799,
        자본총계: 1904
      }
    ]
  },
  // Non Consolidated 별도재무제표
  'non-consolidated': {
    // Income Statement 손익계산서
    is: [
      { year: 2020, 매출액: 9509, 매출총이익: 2454, 영업이익: -1134, 법인세비용차감전이익: -2130, 당기순이익: -2130 },
      { year: 2021, 매출액: 15580, 매출총이익: 4003, 영업이익: -2139, 법인세비용차감전이익: -12766, 당기순이익: -12766 },
      { year: 2022, 매출액: 20336, 매출총이익: 5576, 영업이익: -2289, 법인세비용차감전이익: -2409, 당기순이익: -2409 }
    ],
    // Balance Sheet 재무상태표
    bs: [
      {
        year: 2020,
        유동자산: 2205,
        비유동자산: 3381,
        자산총계: 5586,
        유동부채: 8476,
        비유동부채: 2399,
        부채총계: 10875,
        자본총계: -5289
      },
      {
        year: 2021,
        유동자산: 2722,
        비유동자산: 3926,
        자산총계: 6649,
        유동부채: 2726,
        비유동부채: 2761,
        부채총계: 5487,
        자본총계: 1162
      },
      {
        year: 2022,
        유동자산: 2864,
        비유동자산: 4434,
        자산총계: 7298,
        유동부채: 2594,
        비유동부채: 2834,
        부채총계: 5428,
        자본총계: 1870
      }
    ]
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;

  // content wrapper css start
  width: 100%;
  max-width: calc(1000px + 32px);
  padding: 0px 16px;
  box-sizing: border-box;
  // content wrapper css end

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 64px 0px 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  header.last {
    margin-top: 80px;
  }

  @media screen and (max-width: 992px) {
    header {
      margin: 34px 0 16px;
      span {
        margin-left: 8px;
        white-space: nowrap;
      }
    }

    header.last {
      margin-top: 50px;
    }
  }
`;

const TableWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 992px) {
    overflow-x: scroll;
  }

  p {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #999999;
    white-space: pre-wrap;
    margin: 16px 0px 0px 0px;
  }
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    height: 46px;
    border-bottom: 1px solid #999999;

    & > * {
      display: flex;
      flex: 1;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  .row.header {
    height: 46px;
    font-weight: bold;
  }

  .row.inactive {
    font-weight: bold;
    color: #5f0080;
  }

  @media screen and (max-width: 992px) {
    min-width: 760px;
  }
`;

const Summary = () => {
  const { t, i18n } = useTranslation();
  const { subSubKey } = useSubSubMenu();
  const { is = [], bs = [] } = SUMMARY[subSubKey] || {};

  const sortedIS = useMemo(() => is.sort((a: TableRowInterface, b: TableRowInterface) => b.year - a.year), [is]);
  const sortedBS = useMemo(() => bs.sort((a: TableRowInterface, b: TableRowInterface) => b.year - a.year), [bs]);

  return (
    <Wrapper>
      <header>
        {t('finance.손익계산서')} <span>{t('finance.unit')}</span>
      </header>
      <TableWrapper>
        <TableSection>
          <div className="row header">
            {/* fake empty column  */}
            <span />
            <span>2022</span>
            <span>2021</span>
            <span>2020</span>
          </div>
          {IS_COLUMNS.map((item: any, index: number) => (
            <div className="row" key={index}>
              <span>{t(`finance.is-columns.${item}`)}</span>
              {sortedIS.map((isItem: SummaryInterface, isIndex: number) => (
                <span key={isIndex}>{priceHelper(Object.values(isItem)[index + 1].toString())}</span>
              ))}
            </div>
          ))}
        </TableSection>
        <p>{t('finance.p')}</p>
      </TableWrapper>
      <header className="last">
        {t('finance.재무상태표')} <span>{t('finance.unit')}</span>
      </header>
      <TableWrapper>
        <TableSection>
          <div className="row header">
            {/* fake empty column  */}
            <span />
            <span>2022</span>
            <span>2021</span>
            <span>2020</span>
          </div>
          {BS_COLUMNS.map((item: any, index: number) => (
            <div className={`row ${BS_INACTIVE_COLUMNS.includes(item) ? 'inactive' : ''}`} key={index}>
              <span>{t(`finance.bs-columns.${item}`)}</span>
              {sortedBS.map((bsItem: SummaryInterface, bsIndex: number) => (
                <span key={bsIndex}>{priceHelper(Object.values(bsItem)[index + 1].toString())}</span>
              ))}
            </div>
          ))}
        </TableSection>
      </TableWrapper>
    </Wrapper>
  );
};

export default Summary;
