import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FooterLogo from '../../assets/images/FooterLogo.svg';
import AppleIcon from '../../assets/images/Apple.svg';
import PlayStoreIcon from '../../assets/images/Playstore.svg';
import NaverIcon from '../../assets/images/Naver.svg';
import InstaIcon from '../../assets/images/Insta.svg';
import FacebookIcon from '../../assets/images/Facebook.svg';
import YoutubeIcon from '../../assets/images/Youtube.svg';
import DownArrowIcon from '../../assets/images/DownArrow.png';
import UpArrowIcon from '../../assets/images/UpArrow.png';

const Wrapper = styled.div`
  width: 100%;
  background: #ece6e2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1320px;
  padding: 60px;
  margin: 0 auto;

  span {
    color: #999999;
  }

  span.logo {
    margin-bottom: 16px;

    img {
      width: 80px;
    }
  }

  span.small {
    font-size: 12px;
  }

  @media screen and (max-width: 576px) {
    padding: 32px 16px 64px;

    span.logo {
      margin-bottom: 32px;
    }
  }
`;

const SNSWrapper = styled.div`
  position: absolute;
  right: 60px;

  a {
    margin-left: 16px;
  }

  a:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 576px) {
    position: relative;
    right: unset;
    margin-bottom: 32px;
  }
`;
const ExternalUrls = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-top: 29px;
  position: relative;

  @media screen and (max-width: 576px) {
    align-items: flex-start;
    margin-top: 32px;
  }
`;
const ExternalButton = styled.button`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #999999;
  padding: 10px 16px;
  width: 145px;

  border: 1px solid #999999;
  border-radius: 4px;

  background: none;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    vertical-align: middle;
  }

  img {
    margin-left: 11px;
    width: 20px;
  }
`;
const ExternalList = styled.div`
  width: 145px;
  overflow: hidden;
  position: absolute;
  padding: 12px 0;

  bottom: 99%;
  background: #f9f8f9;
  z-index: 998;
  border-bottom: 1px solid #ece6e2;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);

  display: flex;
  flex-direction: column;

  span:hover {
    cursor: pointer;
  }

  a {
    font-family: 'Noto Sans KR';
    width: 145px;
    height: 24px;
    font-size: 14px;
    background: #f9f8f9;
    text-align: left;
    margin: 4px 0;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: #1a1a1a;
  }

  childrenWrapper.up {
    display: none;
  }
  childrenWrapper.down {
    display: none;
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [isDisplayChildren, setDisplayChildren] = useState(false);
  const outside = useRef<HTMLButtonElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  return (
    <Wrapper
      ref={ref2}
      onClick={e => {
        const { target } = e;
        setDisplayChildren((outside.current?.contains(target as HTMLDivElement) && !isDisplayChildren) || false);
      }}
    >
      <Content>
        <span className="logo">
          <img src={FooterLogo} alt="footer-logo" />
        </span>
        <SNSWrapper>
          {/* <a href="https://www.kurly.com/" target="_blank" rel="noreferrer">
            <img src={AppleIcon} alt="apple-icon" />
          </a>
          <a href="https://www.kurly.com/" target="_blank" rel="noreferrer">
            <img src={PlayStoreIcon} alt="playstore-icon" />
          </a> */}
          <a href="https://www.instagram.com/marketkurly/" target="_blank" rel="noreferrer">
            <img src={InstaIcon} alt="insta-icon" />
          </a>
          <a href="https://www.facebook.com/marketkurly/" target="_blank" rel="noreferrer">
            <img src={FacebookIcon} alt="facebook-icon" />
          </a>
          <a href="https://blog.naver.com/marketkurly/" target="_blank" rel="noreferrer">
            <img src={NaverIcon} alt="naver-icon" />
          </a>
          <a href="https://www.youtube.com/c/마켓컬리marketkurly/" target="_blank" rel="noreferrer">
            <img src={YoutubeIcon} alt="youtube-icon" />
          </a>

          <ExternalUrls>
            <ExternalButton ref={outside}>
              <span>{t('related-site.p')}</span>
              <img src={isDisplayChildren ? DownArrowIcon : UpArrowIcon} alt="uparrow-icon" />
            </ExternalButton>

            {isDisplayChildren && (
              <ExternalList className={`childrenWrapper ${isDisplayChildren ? 'up' : 'down'}`}>
                <a href="https://www.kurly.com/" target="_blank" rel="noreferrer">
                  {t('related-site.list.market-kurly')}
                </a>
                <a href="https://www.kurlynextmile.com/" target="_blank" rel="noreferrer">
                  {t('related-site.list.kurly-nextmile')}
                </a>
              </ExternalList>
            )}
          </ExternalUrls>
        </SNSWrapper>
        <span className="small">Copyright ⓒ Kurly Corp. All Rights Reserved.</span>
      </Content>
    </Wrapper>
  );
};

export default Footer;
