const fileBucketRegExp = /(\d*_|\/)/; // TODO: 나중에 실제로 사용하는 버킷 url로 변경해주어야 함. url 중 슬래시 바로 앞에만 \ 추가해주기

function isKoreanLanguage(language: string) {
  return language.startsWith('ko');
}

function priceHelper(price: string) {
  return parseInt(price).toLocaleString(navigator.language, { minimumFractionDigits: 0 });
}

function leftPad(value: number) {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

function dateHelper(source: string, delimiter = '.') {
  const datetime = new Date(source);
  const year = datetime.getFullYear();
  const month = leftPad(datetime.getMonth() + 1);
  const day = leftPad(datetime.getDate());
  return [year, month, day].join(delimiter);
}

function isEmptyObject(param: any) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

// from. https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
function getYoutubeId(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

const getYoutubeThumbImageUrl = (fullUrl: string) => {
  const PREFIX = 'http://img.youtube.com/vi/';
  const SUFFIX = '/0.jpg';
  const videoId = getYoutubeId(fullUrl);
  return `${PREFIX}${videoId}${SUFFIX}`;
};

const getNameFromBucketUrl = (url: string) => {
  const splitUrls = url ? url.split('/') : [];
  return splitUrls.length > 0 ? splitUrls[splitUrls.length - 1].replace(fileBucketRegExp, '') : '';
};

const encodeFileUrl = (url: string | undefined) => {
  return url ? encodeURI(url) : '';
};

export {
  isKoreanLanguage,
  priceHelper,
  dateHelper,
  isEmptyObject,
  getYoutubeId,
  getYoutubeThumbImageUrl,
  getNameFromBucketUrl,
  encodeFileUrl
};
