import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dropdownAnimation } from '../../assets/styles';
import DropDownIcon from '../../assets/images/DownArrow.png';
import UpArrowIcon from '../../assets/images/UpArrow.png';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { EN_HIDDEN_LIST, INACTIVE_LIST, IS_NEWS_URL } from '../../helpers/constants';

interface DropDownItemProps {
  name: string;
  to: string;
  children?: Array<DropDownItemProps>;
  handleClick?: any;
  isSubMenu?: boolean;
  subSubMenuList?: [SubSubMenuInterface];
}
interface SubSubMenuInterface {
  name: string;
  key: string;
}
interface ParentWrapperProps {
  isDisplayChildren: boolean;
}

interface WrapperProps {
  isSubMenu: boolean;
}

const ParentWrapper = styled.div<ParentWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid ${({ isDisplayChildren }) => (isDisplayChildren ? '#1a1a1a' : '#ece6e2')};
  transition: border-bottom ease-in-out 150ms;

  img {
    width: 24px;
    height: 24px;
  }
`;

const ChildrenItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px 40px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1a1a1a;

  span.subLink {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    line-height: 100%;
  }

  span.subLink:first-child {
    margin-top: 0px;
  }

  span.subSubLink {
    font-weight: normal;
    margin: 24px 0px 0px 18px;
  }

  span:hover {
    cursor: pointer;
  }
`;

const ChildrenWrapper = styled.div<{ isOutsideClick: boolean }>`
  overflow: hidden;

  ${ChildrenItem}:last-child {
    margin-bottom: 32px;
  }

  ${({ isOutsideClick }) =>
    isOutsideClick
      ? css`
          ${dropdownAnimation('0ms')}
        `
      : css`
          ${dropdownAnimation()}
        `}
`;

const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  .title {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    text-decoration: none;
    font-weight: 700;
  }

  ${({ isSubMenu }) =>
    isSubMenu &&
    css`
      ${ParentWrapper} {
        padding: 20px 16px;

        .title {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.02em;
          color: #5f0080;
        }
      }

      ${ChildrenWrapper} {
        position: absolute;
        left: 0;
        right: 0;
        background: white;
        z-index: 998;
        border-bottom: 1px solid #ece6e2;

        ${dropdownAnimation('0ms')}
      }

      ${ChildrenItem} {
        margin: 24px 16px;
      }
    `}
`;

const DropDownItem = ({ name, to, children = [], isSubMenu = false, handleClick }: DropDownItemProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const outsideRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [isDisplayChildren, setIsDisplayChildren] = useState(false);
  const [isOutsideClick, setIsOutsideClick] = useState(false);

  const handleClickChildren = (childPath: string, subSubPath?: string) => {
    if (handleClick) {
      handleClick();
    }

    window.scrollTo(0, 0);
    setIsDisplayChildren(false);

    if (IS_NEWS_URL) {
      navigate('redirect', { replace: true, state: { path: `${to}/${childPath}${subSubPath ? '/' + subSubPath : ''}` } });
    } else {
      navigate(`${to}/${childPath}${subSubPath ? '/' + subSubPath : ''}`, { replace: true });
    }
  };

  useEffect(() => {
    function handleClickOutside(event: React.BaseSyntheticEvent | MouseEvent) {
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        setIsOutsideClick(true);
        setIsDisplayChildren(false);
      } else {
        setIsOutsideClick(false);
      }
    }
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [outsideRef]);

  return (
    <Wrapper isSubMenu={isSubMenu} ref={outsideRef}>
      <ParentWrapper isDisplayChildren={isDisplayChildren} onClick={() => setIsDisplayChildren(!isDisplayChildren)}>
        <span className="title">{name}</span>
        {isDisplayChildren ? <img src={UpArrowIcon} alt="up" /> : <img src={DropDownIcon} alt="down" />}
      </ParentWrapper>
      <ChildrenWrapper className={isDisplayChildren ? 'up' : 'down'} isOutsideClick={isOutsideClick}>
        {children.map((item, index) => {
          const subsubMenuList = item?.subSubMenuList || [];
          return (
            !(i18n.language === 'en' && EN_HIDDEN_LIST.includes(item.to)) && (
              <ChildrenItem key={index}>
                <span
                  className={`subLink ${INACTIVE_LIST.includes(item.to) ? 'inactive' : ''}`}
                  onClick={() => handleClickChildren(item.to)}
                >
                  {t(`submenu.${item.to}`)}
                </span>
                {subsubMenuList.length > 0 &&
                  subsubMenuList.map((subMenu, index) => {
                    return (
                      <span
                        key={index}
                        className={`subSubLink ${INACTIVE_LIST.includes(subMenu.key) ? 'inactive' : ''}`}
                        onClick={() => handleClickChildren(item.to, subMenu.key)}
                      >
                        {t(`subsubmenu.${subMenu.key}`)}
                      </span>
                    );
                  })}
              </ChildrenItem>
            )
          );
        })}
      </ChildrenWrapper>
    </Wrapper>
  );
};

export default DropDownItem;
