import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { imageUrl, imageUrlEng } from '../../assets/images/urls';
import { useMediaQuery } from 'react-responsive';
import OverviewIcon1 from '../../assets/images/overviewIcon1.png';
import OverviewIcon2 from '../../assets/images/overviewIcon2.png';
import OverviewIcon3 from '../../assets/images/overviewIcon3.png';
import { isKoreanLanguage } from '../../helpers';

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1080px;
  position: relative;
  align-items: center;

  article {
  }

  img {
    width: 100%;
    max-width: 760px;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    text-align: center;
    padding: 0 40px 24px;
    margin: 0;
    white-space: pre-line;

    .bold {
      font-weight: 700;
    }

    .pcBreak {
      display: block;
    }
  }

  // TODO: 모바일에서 1:1비율로 이미지 노출되도록
  @media screen and (max-width: 768px) {
    img {
      /* width: auto;
      height: auto;
      overflow: hidden; */
    }

    h3 {
      white-space: unset;

      .pcBreak {
        display: none;
      }
    }
  }
`;

const OverviewContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px;
  width: 100%;
  box-sizing: border-box;

  header {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #5f0080;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    white-space: pre-line;
    word-break: keep-all;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin: 0;

    img {
      display: block;
      width: 376px;
    }
  }
`;

const OverviewProblemWrapper = styled.section`
  font-family: 'Noto Sans KR';
  font-style: normal;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  line-height: 150%;
  text-align: center;

  .problemIconHeader {
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    color: #5f0080;
  }

  .problemWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 37px;
  }

  .problemContent {
    width: 332px;
    margin-right: 24px;
    margin-bottom: 80px;

    :last-child {
      margin-right: 0;
    }
  }

  .problemHeader {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 16px;
    margin-bottom: 24px;
    height: 56px;
    background: #ece6e2;
  }

  ul {
    padding-left: 20px;
    margin: 24px 0 0 0;
  }

  li {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  img {
    width: 72px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    .problemWrapper {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 64px;
    }

    .problemContent {
      width: 100%;
      margin: 32px 0 0 0;

      :last-child {
        margin-left: 0;
      }
    }

    img {
      margin-top: 0;
    }
  }
`;

const OverviewVisionWrapper = styled.div`
  padding: 0 13px;
  margin-right: auto;

  header {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;

    letter-spacing: -0.02em;
    color: #5f0080;

    margin-bottom: 2px;
    margin-top: 24px;
  }

  p,
  li {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    letter-spacing: -0.02em;

    /* Base/Black */

    color: #1a1a1a;
  }

  ul {
    padding-left: 20px;
  }
`;

const Overview = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const urls = isKoreanLanguage(i18n.language) ? imageUrl : imageUrlEng;

  const problems: any = t('company.overview.problems', { returnObjects: true });
  const visions: any = t('company.overview.visions', { returnObjects: true });
  const OVERVIEW_ICONS = [OverviewIcon1, OverviewIcon2, OverviewIcon3];

  return (
    <OverviewWrapper>
      <h3>{'컬리는 더 나은 삶을 위한 올바른 혁신을 이끌어가는 기업으로서\n함께 성장하고 조화를 이루는 커머스의 미래를 만들어 갑니다.'}</h3>
      <OverviewContent>
        <header>{t('company.overview.h1')}</header>
        <h3>
          {isKoreanLanguage(i18n.language) ? (
            <span>
              컬리는 <strong>파트너사와의 동반성장, 친환경 경영, 사회적 기여</strong>
              {'를 통해\n지속가능한 경영을 실천하고 있습니다.'}
            </span>
          ) : (
            <span>
              Kurly has shared <strong>growth with partners, eco-friendly management,</strong> and{' '}
              <strong>contribution to the community,</strong>
              {'\nfostering mutual growth and developing sustainable management.'}
            </span>
          )}
        </h3>
        <img src={urls.overview1Pc} alt="img" style={{ maxWidth: '600px', marginTop: '24px' }} />
      </OverviewContent>
      <OverviewContent>
        <header>{t('company.overview.h2')}</header>
        <img src={isMobile ? urls.overview2Mobile : urls.overview2Pc} alt="img" />
        {isMobile &&
          visions.map((vision: any, index: number) => (
            <OverviewVisionWrapper key={index}>
              <header style={{ textAlign: 'unset' }}>{vision.t}</header>
              <p>{vision.p}</p>
              {vision?.li && (
                <ul>
                  {vision.li.map((item: any, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
            </OverviewVisionWrapper>
          ))}
      </OverviewContent>
      <OverviewContent>
        <header>{t('company.overview.h3')}</header>
        {problems.map((problem: any, index: number) => (
          <OverviewProblemWrapper key={index}>
            <img src={OVERVIEW_ICONS[index]} alt="icon" />
            <div className="problemIconHeader">{problem.h}</div>
            <div className="problemWrapper">
              <div className="problemContent">
                <div className="problemHeader">{t('company.overview.challenges-header.challenge')}</div>
                <ul>
                  {problem.p.map((item: any, index: number) => (
                    <li className="problemBody" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="problemContent">
                <div className="problemHeader">{t('company.overview.challenges-header.value')}</div>
                <ul>
                  {problem.v.map((item: any, index: number) => (
                    <li className="problemBody" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="problemContent">
                <div className="problemHeader">{t('company.overview.challenges-header.solution')}</div>
                <ul>
                  {problem.a.map((item: any, index: number) => (
                    <li className="problemBody" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </OverviewProblemWrapper>
        ))}
      </OverviewContent>
    </OverviewWrapper>
  );
};

export default Overview;
