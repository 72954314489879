import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Skeleton from '../../components/Skeleton';
import { useSubSubMenu } from '../../context/router';
import { apiAxios } from '../../helpers/axios';
import { dateHelper, isEmptyObject, isKoreanLanguage } from '../../helpers';
import StringDropDownItem from '../../components/DropDown/StringDropDown';

interface IndexSignatureInterface {
  [key: string]: any; // index signature
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 150%;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  color: #1a1a1a;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 24px;
    color: #5f0080;
    line-height: 100%;
    white-space: pre-wrap;

    // dropdown wrapper
    & > div {
      width: 140px;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    margin-top: 24px;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 24px;

      .dropdownWrapper {
        margin-top: 0px;
        width: 110px;

        .parentWrapper {
          padding: 0px;
        }

        .childrenWrapper {
          top: 0px;
          left: unset;
          right: 0px;
          border-bottom: none;
          background: #f9f8f9;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
          width: 150px;
          z-index: 990;

          & > div {
            margin: 24px 10px;
            color: #1a1a1a;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    margin: 0px 0px 24px 0px;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;
    line-height: 100%;
  }

  @media screen and (max-width: 1080px) {
    h4 {
      margin: 32px 0 22px;
    }
  }
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #999999;

    & > * {
      display: flex;
      flex: 1;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 150%;
      padding: 24px 0px;
      letter-spacing: -0.02em;
      /* border: 1px solid black; */
    }

    span.name {
      flex: unset;
      width: 110px;
      font-weight: 700;
      color: #5f0080;
      align-self: flex-start;
    }

    span.appointedAt {
      flex: unset;
      width: 180px;
      align-self: flex-start;
    }

    p.history {
      justify-content: flex-start;
      padding-left: 32px;
      white-space: pre-wrap;
      margin: 0;
      text-align: left;
      line-height: 200%;
    }

    span.order {
      flex: unset;
      width: 220px;
    }

    span.date {
      flex: unset;
      width: 180px;
    }

    span.pass {
      flex: unset;
      width: 180px;
    }

    .orderWrapper {
      width: 400px;
      flex: 0;
    }

    .agendaWrapper {
      display: flex;
      flex-direction: column;

      .agenda {
        display: flex;
        width: 100%;
      }
    }
  }

  .row:last-child {
    margin-bottom: 80px;
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;
    font-weight: bold;
  }

  .row:not(.header) {
    span.order {
      align-self: flex-start;
    }

    span.date {
      align-self: flex-start;
    }

    span.content {
      flex: 1;
      text-align: left;
      margin-left: 24px;
    }

    span.pass {
      color: #5f0080;
    }
  }

  @media screen and (max-width: 1080px) {
    border-top: 1px solid #1a1a1a;

    .row {
      padding: 16px 0px 0px;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 0;

      span.name {
        width: unset;
        padding: 8px 0;
      }

      span.appointedAt {
        width: unset;
        padding: 8px 0;
      }

      p.history {
        padding: 8px 0;
      }

      span.order {
        width: unset;
        padding: 0px;
      }

      span.date {
        width: unset;
        padding: 0px;
      }

      .orderWrapper {
        padding: 8px 0 24px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #1a1a1a;
      }

      .agendaWrapper {
        padding: 2px 0;
        width: 100%;

        .agenda {
          padding: 24px 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-bottom: 1px solid #999999;
        }

        .agenda:last-child {
          border-bottom: 1px solid #1a1a1a;
        }

        span.content {
          flex: 1;
          text-align: left;
          margin: 0;
        }

        span.pass {
          margin-top: 8px;
          color: #5f0080;
          text-align: left;
        }
      }
    }

    .row:last-child {
      margin-bottom: 32px;
    }

    .row.header {
      display: none;
    }
  }
`;

const GovernanceShareholder = () => {
  const { subSubKey } = useSubSubMenu();

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const [selectedYear, setSelectedYear] = useState('');
  const [parsedShareholders, setParsedShareholders] = useState<IndexSignatureInterface>({});

  const scheduledYears = useMemo<string[]>(() => Object.keys(parsedShareholders).reverse(), [parsedShareholders]);

  const { isLoading, isError, data = {} } = useQuery(['companyGovernanceShareholder', languageId, subSubKey], () =>
    apiAxios({
      method: 'GET',
      uri: `/company/infos?language_id=${languageId}&submenu=${subSubKey}&limit=100`
    })
  );

  useEffect(() => {
    const { total, items = [] } = data;
    const [shareholderObject = {}] = items || [];
    if (isEmptyObject(shareholderObject)) return;

    const jsonData = shareholderObject?.data_t || {};
    const { meetings } = Object.keys(jsonData).reduce(
      function(acc: any, cur: any) {
        // parse shareholders data by year number
        if (cur === 'meetings') {
          jsonData[cur]
            .sort((a: any, b: any) => b.date - a.date)
            .forEach((meeting: any) => {
              const year = new Date(meeting.date).getFullYear().toString();
              acc[cur][year] = [...(acc[cur][year] || []), meeting];
            });
        }
        return acc;
      },
      {
        meetings: {}
      }
    );

    // TODO: 정렬
    setParsedShareholders({ ...meetings });
  }, [data]);

  useEffect(() => {
    if (scheduledYears.length === 0 || selectedYear) return;
    setSelectedYear(scheduledYears[0]);
  }, [scheduledYears]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleClickDropdown = (value: string) => {
    setSelectedYear(value);
  };

  return (
    <Wrapper>
      <header>
        {t('company.shareholders.h1')}
        <div className="dropdownWrapper">
          <StringDropDownItem
            name={selectedYear ? `${selectedYear}${t('year')}` : t('year2')}
            children={scheduledYears}
            handleClick={handleClickDropdown}
          />
        </div>
      </header>
      <TableWrapper>
        {selectedYear && parsedShareholders[selectedYear] && (
          <TableSection>
            <div className="row header">
              <span className="order">{t('title2')}</span>
              <span className="date">{t('date3')}</span>
              <span className="agenda">{t('agenda')}</span>
              <span className="pass">{t('result2')}</span>
            </div>
            {(parsedShareholders[selectedYear] || [])
              .sort((a: any, b: any) => parseInt(b.order) - parseInt(a.order))
              .map((meeting: any, index: number) => {
                return (
                  <div className="row" key={index}>
                    <div className="orderWrapper">
                      <span className="order">{meeting.title}</span>
                      <span className="date">{dateHelper(meeting.date)}</span>
                    </div>
                    <div className="agendaWrapper">
                      {(meeting?.agendas || []).map((agenda: any, index: number) => (
                        <div className="agenda" key={index}>
                          <span className="content">{agenda.content}</span>
                          <span className="pass">{agenda.pass}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
          </TableSection>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

export default GovernanceShareholder;
