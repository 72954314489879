import styled from 'styled-components';

interface MoreButtonProps {
  onClickHandler?: any;
  marginTop?: any;
}

const Wrapper = styled.div`
  margin-top: 64px;
  align-self: center;

  .moreButton {
    width: 139px;
    height: 48px;
    background: #5f0080;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    border: none;
  }
  @media screen and (max-width: 992px) {
    margin-top: 32px;

    .moreButton {
      width: 97px;
      height: 49px;
    }
  }
  width: 97px;
  height: 49px;
`;

const MoreButton = ({ onClickHandler }: MoreButtonProps) => (
  <Wrapper>
    <button className="moreButton" onClick={() => onClickHandler()}>
      더보기
    </button>
  </Wrapper>
);

export default MoreButton;
