import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EN_HIDDEN_LIST, INACTIVE_LIST, IS_NEWS_URL, MENU } from '../../helpers/constants';
import DropDownItem from '../DropDown';

interface SubMenuInterface {
  name: string;
  to: string;
}

const Wrapper = styled.div`
  border-top: 1px solid #ece6e2;
  margin: 100px 0 0 0;
  padding: 64px 0px;

  @media screen and (max-width: 992px) {
    padding: 0px 16px;
  }
`;

const PCWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div:first-child {
    margin-left: 0px;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans KR';
  margin-left: 120px;

  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #999999;
    margin-bottom: 20px;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;

const BottomNav = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <PCWrapper>
        <Column>
          <h4>{t(`menu.company`)}</h4>
          {MENU['company'].subMenuList.map((menu: SubMenuInterface, index: number) => {
            return (
              <Link
                className={INACTIVE_LIST.includes(menu.to) ? 'inactive' : ''}
                key={index}
                to={IS_NEWS_URL ? '/redirect' : `company/${menu.to}`}
                state={{ path: `company/${menu.to}` }}
              >
                {t(`submenu.${menu.to}`)}
              </Link>
            );
          })}
        </Column>
        <Column>
          <h4>{t(`menu.finance`)}</h4>
          {MENU['finance'].subMenuList.map((menu: SubMenuInterface, index: number) => {
            return (
              <Link
                className={INACTIVE_LIST.includes(menu.to) ? 'inactive' : ''}
                key={index}
                to={IS_NEWS_URL ? '/redirect' : `finance/${menu.to}`}
                state={{ path: `finance/${menu.to}` }}
              >
                {t(`submenu.${menu.to}`)}
              </Link>
            );
          })}
        </Column>
        {/* <Column>
          <h4>{t(`menu.stock`)}</h4>
          {MENU['stock'].subMenuList.map((menu: SubMenuInterface, index: number) => {
            return (
              <Link
                className={INACTIVE_LIST.includes(menu.to) ? 'inactive' : ''}
                key={index}
                to={IS_NEWS_URL ? '/redirect' : `stock/${menu.to}`}
                state={{ path: `stock/${menu.to}` }}
              >
                {t(`submenu.${menu.to}`)}
              </Link>
            );
          })}
        </Column> */}
        <Column>
          <h4>{t(`menu.ir`)}</h4>
          {MENU['ir'].subMenuList.map(
            (menu: SubMenuInterface, index: number) =>
              !(i18n.language === 'en' && EN_HIDDEN_LIST.includes(menu.to)) && (
                <Link
                  className={INACTIVE_LIST.includes(menu.to) ? 'inactive' : ''}
                  key={index}
                  to={IS_NEWS_URL ? '/redirect' : `ir/${menu.to}`}
                  state={{ path: `ir/${menu.to}` }}
                >
                  {t(`submenu.${menu.to}`)}
                </Link>
              )
          )}
        </Column>
        <Column>
          <h4>{t(`menu.media`)}</h4>
          {MENU['media'].subMenuList.map((menu: SubMenuInterface, index: number) => {
            return (
              <Link
                className={INACTIVE_LIST.includes(menu.to) ? 'inactive' : ''}
                key={index}
                to={IS_NEWS_URL ? '/redirect' : `media/${menu.to}`}
                state={{ path: `media/${menu.to}` }}
              >
                {t(`submenu.${menu.to}`)}
              </Link>
            );
          })}
        </Column>
      </PCWrapper>
      <MobileWrapper>
        <DropDownItem name={t(`menu.company`)} to="company" children={MENU['company'].subMenuList} />
        <DropDownItem name={t(`menu.finance`)} to="finance" children={MENU['finance'].subMenuList} />
        {/* <DropDownItem name={t(`menu.stock`)} to="stock" children={MENU['stock'].subMenuList} /> */}
        <DropDownItem name={t(`menu.ir`)} to="ir" children={MENU['ir'].subMenuList} />
        <DropDownItem name={t(`menu.media`)} to="media" children={MENU['media'].subMenuList} />
      </MobileWrapper>
    </Wrapper>
  );
};

export default BottomNav;
