import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { apiAxios } from '../../helpers/axios';
import { dateHelper, isKoreanLanguage } from '../../helpers';
import ArrowIcon from '../../assets/images/BlackArrow.png';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PaginationBar from '../../components/Pagination/OffSet';

interface TableRowInterface {
  id: number;
  title_t: string;
  datetime: string;
  file_urls_t?: string[];
}

interface SortedDataInterface {
  [year: string]: any; // index signature
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    height: 64px;
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #999999;

    img {
      display: none;
    }

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;
  }

  .row:not(.header) {
    span:first-child {
      // color: #999999;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1080px) {
    .row {
      height: unset;
      padding: 30px 0px;
      flex-direction: column;
      align-items: flex-start;

      span:first-child {
        order: 2;
        margin-top: 16px;
        color: #999999;
      }

      span:nth-child(2) {
        margin-right: 24px;
      }

      img {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        right: 0px;
        top: calc(50% - 12px);
      }
    }

    .row.header {
      display: none;
    }
  }
`;

const Report = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [itemList, setItemList] = useState<SortedDataInterface>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const itemPerPage = isMobile ? 6 : 9;

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['irBoards', page],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/ir/boards?page=${page}&limit=${itemPerPage}`
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.items) {
      setItemList(data.items);
      setTotal(data.total);
      window.scrollTo({
        top: 0
      });
    }
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError || !isKoreanLanguage(language)) {
    return <Navigate to="/" />;
  }

  const handleClickRow = (id: number) => {
    navigate(`/ir/report/${id}`);
  };

  const handleNext = () => {
    if (page < Math.ceil(total / itemPerPage)) setPage(prevPage => prevPage + 1);
  };

  const handlePrev = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  const handleClickPage = (page: number) => {
    setPage(page);
  };

  return (
    <Wrapper>
      <header>공고</header>
      <ContentWrapper>
        <TableSection>
          <div className="row header">
            <span>일자</span>
            <span>제목</span>
          </div>
          {itemList.length > 0 &&
            itemList.map((item: TableRowInterface, index: number) => (
              <div className="row" key={index} onClick={() => handleClickRow(item.id)}>
                <span>{dateHelper(item.datetime, '-')}</span>
                <span>{item.title_t}</span>
                <img src={ArrowIcon} alt="arrow" />
              </div>
            ))}
        </TableSection>
        {!isFetching && (
          <PaginationBar
            clickPageHandler={handleClickPage}
            clickNext={handleNext}
            clickPrev={handlePrev}
            pageTotal={total && Math.ceil(total / itemPerPage)}
            currentPage={page}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Report;
