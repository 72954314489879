import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiAxios } from '../../helpers/axios';
import { dateHelper, encodeFileUrl, getNameFromBucketUrl } from '../../helpers';
import { HTMLContent } from '../IR/ReportDetail';
import DownloadIcon from '../../assets/images/Download.png';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ThumbnailInterface {
  imageUrl: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  max-width: calc(1000px + 32px);
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  h3 {
    margin: 0px 0px 12px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }

  .datetime {
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #999999;
  }

  .buttonWrapper {
    width: 100%;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 0px;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }

  button.goBack {
    padding: 14px 24px;
    border: 1px solid #1a1a1a;
    align-self: flex-start;
  }

  button.article {
    padding: 14px 24px;
    margin: 0 auto;
    background: #5f0080;
    color: white;
    font-size: 16px;
    margin-bottom: 56px;
  }

  & > div:last-child {
    border-top: 1px solid #999999;
    padding-top: 24px;
  }

  @media screen and (max-width: 992px) {
    h3 {
      width: calc(100% - 40px);
      word-break: keep-all;
      text-align: center;
    }

    button.article {
      margin-bottom: 42px;
    }
  }
`;

const NewsRoomHTMLContent = styled(HTMLContent)`
  margin: 0;
  padding: 40px 0px 72px 0px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 12px;
    text-align: left;

    strong {
      font-weight: 700;
      font-size: 24px;
      text-align: left;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    text-align: left;

    strong {
      font-weight: bold;
      text-align: left;
    }
  }

  img {
    max-width: 100%;
    width: 700px;
    margin-bottom: 40px;
    border-radius: 4px;
  }

  @media screen and (max-width: 992px) {
    padding: 40px 12px;
  }
`;

const FileWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #1a1a1a;
  font-size: 14px;

  .fileItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 28px 0px 30px;
  }

  @media screen and (max-width: 992px) {
    box-sizing: border-box;
    padding: 0px 16px;

    .fileItem {
      margin: 24px 0px 26px;

      span {
        width: calc(100% - 100px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      button {
        white-space: nowrap;
      }
    }
  }
`;

const ThumbnailWrapper = styled.div<ThumbnailInterface>`
  width: 700px;
  margin-top: 60px;

  .thumbnail {
    width: 100%;
    padding-bottom: 85%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: #e4e4e4;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const NewsRoomDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isLoading, isError, data } = useQuery('mediaNewsById', () =>
    apiAxios({
      method: 'GET',
      uri: `/media/news/${id}`
    })
  );

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/media/news" />;
  }

  return (
    <Wrapper>
      <h3>{data.title_t}</h3>
      <span className="datetime">{dateHelper(data.datetime, '-')}</span>
      {/* {data?.thumbnail_url && (
        <ThumbnailWrapper imageUrl={encodeFileUrl(data.thumbnail_url)}>
          <div className="thumbnail" />
        </ThumbnailWrapper>
      )} */}
      <NewsRoomHTMLContent dangerouslySetInnerHTML={{ __html: data.content_t }} />
      {data.file_urls_t && data.file_urls_t.length > 0 && (
        <FileWrapper>
          {data.file_urls_t.map(
            (file: string, index: number) =>
              file && (
                <div className="fileItem" key={index}>
                  <span>{getNameFromBucketUrl(file)}</span>
                  <button onClick={() => window.open(file, '_blank')}>
                    {t('download')}
                    <img src={DownloadIcon} alt="download" />
                  </button>
                </div>
              )
          )}
        </FileWrapper>
      )}
      {data?.article_url && (
        <div className="buttonWrapper">
          <button className="article" onClick={() => window.open(data.article_url, '_blank')}>
            {t('fullArticle')}
          </button>
        </div>
      )}
      <div className="buttonWrapper">
        <button className="goBack" onClick={() => navigate(`/media/news-room/${data.submenu}`)}>
          {t('list')}
        </button>
      </div>
    </Wrapper>
  );
};

export default NewsRoomDetail;
