import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { apiAxios } from '../../helpers/axios';
import { useMemo, useState, useEffect } from 'react';
import { isKoreanLanguage } from '../../helpers';
interface Item {
  id: number;
  title_t: string;
  year_month: string;
  language_id: number;
  createdAt: string;
  updatedAt: string;
  LanguageId: number;
}

interface TransformedItem {
  year: number;
  contents: { title: string; year_month: string }[];
}

interface TransformedGroup {
  year10: number;
  years: TransformedItem[];
  start: number;
  end: number;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  max-width: 1000px;
  width: 100%;
  position: relative;

  h3 {
    /* Title 2 */
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */
    letter-spacing: -0.02em;
    /* Base/Black */
    color: #1a1a1a;
    margin-top: 74px;
  }

  h3:first-child {
    margin-top: 0px;
  }

  @media screen and (max-width: 992px) {
    padding: 32px 16px;
    box-sizing: border-box;
  }
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #1a1a1a;

  span {
    display: inline-block;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  .row.head {
    margin-top: 32px;
  }

  .row.last {
    border-bottom: 1px solid #999999;
    padding-bottom: 32px;
    margin-bottom: 0px;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;

    .year {
      width: 110px;
    }

    .year.head {
      /* Br/Title 4 Br */
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #5f0080;
    }

    .yearWrapper {
      display: flex;

      span:first-child {
        width: 130px;
      }

      span {
        white-space: pre-wrap;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .row {
      flex-direction: column;
      align-items: flex-start;

      .year.head {
        margin-bottom: 24px;
      }

      .yearWrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        span:first-child {
          width: 80px;
        }

        span:nth-child(2) {
          word-break: keep-all;
          width: calc(100% - 32px - 40px);
        }
      }
    }
  }
`;

const History = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const [groupedData, setGroupedData] = useState([]);

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['history', languageId],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/company/history?language_id=${languageId}`
      }),
    { keepPreviousData: true }
  );

  function getYear10(year: number): number {
    const year10 = Math.floor((year % 100) / 10);
    if (year % 10 == 0) {
      return year10 - 1;
    }
    return year10;
  }

  function groupByYear(items: Item[]): TransformedGroup[] {
    // Grouping and transformation
    const transformedData: TransformedGroup[] = [];
    const groupedData: { [key: string]: TransformedItem[] } = {};

    for (const item of items) {
      const year = Number(item.year_month.substring(0, 4));
      const year10 = getYear10(year);

      if (!groupedData[year10]) {
        groupedData[year10] = [];
      }

      if (!groupedData[year10].some(group => group.year === year)) {
        groupedData[year10].push({ year, contents: [] });
      }

      const group = groupedData[year10].find(group => group.year === year)!;
      group.contents.push({ title: item.title_t, year_month: item.year_month.replace('-', '.') });
    }

    Object.keys(groupedData)
      .sort()
      .reverse()
      .forEach((year10, index) => {
        const sortedGroupedData = groupedData[year10].sort((a, b) => b.year - a.year);

        transformedData.push({
          year10: Number(year10),
          years: sortedGroupedData,
          start:
            index === Object.keys(groupedData).length - 1
              ? sortedGroupedData[sortedGroupedData.length - 1].year
              : parseInt('20' + year10 + '1'),
          end: index === 0 ? sortedGroupedData[0].year : parseInt('20' + (Number(year10) + 1) + '0')
        });
      });

    return transformedData;
  }

  useEffect(() => {
    if (data && data?.items) {
      setGroupedData(data && groupByYear(data.items));
    }
  }, [data]);

  return (
    <Wrapper>
      {groupedData.map((yearsItem: any) => {
        return (
          <>
            <h3>{`${yearsItem.start} - ${yearsItem.end}`}</h3>
            <StyledTable>
              {yearsItem.years.map((yearItems: any, index: number) => {
                return yearItems.contents.map((item: any, index: number) => {
                  const isHead = index === 0;
                  const isLastItem = index === yearItems.contents.length - 1;
                  return (
                    <div className={`row ${isHead ? 'head' : ''} ${isLastItem ? 'last' : ''}`} key={index}>
                      {<span className={`year ${isHead ? 'head' : ''}`}>{isHead ? yearItems.year : ''}</span>}
                      <div className="yearWrapper">
                        <span>{item.year_month}</span>
                        <span>{item.title}</span>
                      </div>
                    </div>
                  );
                });
              })}
            </StyledTable>
          </>
        );
      })}
    </Wrapper>
  );
};

export default History;
