import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NotFoundLogo from '../assets/images/404Logo.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.3px;

  padding: 160px 0 200px;

  img {
    width: 357px;
  }

  p {
    margin: 48px 0 56px;
  }

  button {
    border: none;
    width: 140px;
    height: 48px;
    background: #5f0080;
    color: #ffffff;

    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.02em;
  }

  @media screen and (max-width: 992px) {
    img {
      width: 217px;
    }

    p {
      padding: 0 48px;
      margin: 32px 0 48px;
    }
  }
`;

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={NotFoundLogo} alt="404 logo" />
      <p>{t('notfound.p')}</p>
      <button
        onClick={() => {
          navigate('/');
        }}
      >
        {t('notfound.button')}
      </button>
    </Wrapper>
  );
};

export default NotFound;
