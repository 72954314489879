import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dropdownAnimation } from '../../assets/styles';
import DownloadIcon from '../../assets/images/Download.png';
import DropDownIcon from '../../assets/images/DownArrow.png';
import UpArrowIcon from '../../assets/images/UpArrow.png';
import styled from 'styled-components';
import { getNameFromBucketUrl } from '../../helpers';

interface DropDownItemProps {
  name: string;
  children?: string[];
  handleClick?: any;
}

interface ParentWrapperProps {
  isDisplayChildren: boolean;
}

const ParentWrapper = styled.div<ParentWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  border-bottom: 1px solid ${({ isDisplayChildren }) => (isDisplayChildren ? '#ffffff' : '#ece6e2')};
  transition: border-bottom ease-in-out 150ms;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ChildrenItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px 32px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #999999;
`;

const ChildrenWrapper = styled.div`
  overflow: hidden;

  ${dropdownAnimation('0ms')}
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .title {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    overflow: auto;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    text-decoration: none;
  }
`;

const FileDropDownItem = ({ name, children = [], handleClick }: DropDownItemProps) => {
  const [isDisplayChildren, setIsDisplayChildren] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClickChildren = (item: string) => {
    if (handleClick) {
      handleClick(item);
    }
  };

  return (
    <Wrapper>
      <ParentWrapper
        className="parentWrapper"
        isDisplayChildren={isDisplayChildren}
        onClick={() => setIsDisplayChildren(!isDisplayChildren)}
      >
        <span className="title">{name}</span>
        {isDisplayChildren ? <img src={UpArrowIcon} alt="up" /> : <img src={DropDownIcon} alt="down" />}
      </ParentWrapper>
      <ChildrenWrapper className={`childrenWrapper ${isDisplayChildren ? 'up' : 'down'}`}>
        {children.map(
          (item, index) =>
            item && (
              <ChildrenItem key={index}>
                <button className="subLink" onClick={() => handleClickChildren(item)}>
                  {index === 0 ? t('report2') : 'Excel'}
                  <img src={DownloadIcon} alt="download" />
                </button>
              </ChildrenItem>
            )
        )}
      </ChildrenWrapper>
    </Wrapper>
  );
};

export default FileDropDownItem;
