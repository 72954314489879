import axios from 'axios';

interface ApiAxiosInterface {
  method: string;
  uri: string;
  data?: any;
  configs?: any;
}

const apiAxios = async ({ method, uri, data, configs }: ApiAxiosInterface) => {
  const url = '/api'; //process.env.REACT_APP_API_URL;
  const { status, data: apiData } = await axios({
    method: method,
    url: url + uri,
    data: data,
    ...configs
  });

  if (status !== 200) {
    // throw new Error(status);
  }

  return apiData;
};

export { apiAxios };
