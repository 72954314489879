import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { imageUrl } from '../assets/images/urls';
import Skeleton from '../components/Skeleton';
import { dateHelper, encodeFileUrl, isEmptyObject, isKoreanLanguage } from '../helpers';
import { apiAxios } from '../helpers/axios';

interface ThumbnailInterface {
  imageUrl: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;

  * {
    font-family: 'Noto Sans KR';
    font-style: normal;
    padding: 0;
    margin: 0;
  }

  article {
    display: flex;
    flex-direction: row;
    margin: 64px 0px 0px 0px;

    & > * {
      display: flex;
      flex: 5;
      flex-direction: column;
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #5f0080;
      flex: 4;
    }

    h3 {
      margin: 20px 0px;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #1a1a1a;
    }

    section {
      margin-left: 42px;

      .clickable:hover {
        cursor: pointer;
      }

      .contents {
        display: flex;
        flex-direction: row;
        padding: 24px 0px;
        box-sizing: border-box;

        .thumbnailWrapper {
          margin-left: 15px;
          width: calc(100% / 3 - 10px);
          height: 256px;
          flex-shrink: 0;

          :first-child {
            margin-left: 0px;
          }

          p {
            display: flex;
            height: 100%;
            flex-direction: column;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            text-align: center;

            margin: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #ffffff;
          }
        }
      }

      .media {
        p {
          font-size: 18px;
          span {
            margin-top: 16px;
            color: #999999;
            font-size: 14px;
          }
        }
      }

      .ir {
        margin-bottom: 24px;
        hr {
          background-color: rgb(26, 26, 26);
        }

        :last-child {
          margin-bottom: 0px;
          hr {
            display: none;
          }
        }

        p {
          font-size: 18px;
          span {
            margin-top: 22px;
            margin-bottom: 40px;
            color: #999999;
            font-size: 14px;
            width: 100%;
          }
        }
        hr {
          background-color: #ece6e2;
          height: 1px;
          border: 0px;
          margin-top: 24px;
        }

        .title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 5px 0;
        }

        .irHeader {
          display: flex;
        }

        .irHead {
          display: inline-block;
          width: calc(100% - 50px);
        }

        .more {
          width: 70px;
          text-align: right;
          color: #999999;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    section.ir {
      width: 300px;
      flex-shrink: 1;
      border-bottom: 1px solid rgb(26, 26, 26);
      margin-bottom: 23px;
    }

    header {
      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #1a1a1a;

        &:hover {
          cursor: pointer;
        }
      }
    }

    p {
      a,
      span {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #999999;
        text-decoration: none;
        margin: 20px 0px;
      }

      span {
        color: #1a1a1a;
      }
    }
  }

  @media screen and (max-width: 992px) {
    article {
      flex-direction: column;
      margin: 0px;
      padding: 16px 0px 0px;

      h2 {
        margin-top: 16px;
      }

      h3 {
        margin-bottom: 10px;
      }

      section {
        margin: 0;
        padding: 32px 0px;

        .contents {
          flex-direction: column;
          padding: 22px 0 0;

          .thumbnailWrapper {
            width: 100%;
            height: 109px;
            margin-left: 0;
            margin-bottom: 12px;

            :last-child {
              margin-bottom: 0;
            }
          }

          p {
            padding: 0px;

            span {
              margin-top: 12px;
              color: #999999;
            }
          }

          h3 {
            margin: 0;
            font-weight: 400;
            font-size: 18px;
            margin-top: 16px;
          }
        }

        .media {
          p {
            span {
              margin-bottom: 0;
            }
          }
        }

        .ir {
          :last-child {
            margin-bottom: 0;
          }
        }
      }

      section.ir {
        width: 100%;
      }

      section.press {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
`;

const Thumbnail = styled.div<ThumbnailInterface>`
  width: 100%;
  padding-bottom: 85%;
  border-radius: 4px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  background-color: #e4e4e4;
  margin-bottom: 16px;
`;

const LongThumbnail = styled.div<ThumbnailInterface>`
  height: 100%;
  border-radius: 4px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  background-color: #e4e4e4;
  margin-bottom: 16px;

  justify-content: center;
`;

const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const { isLoading, isError, data = [] } = useQuery(['mainContents', languageId], () =>
    apiAxios({
      method: 'GET',
      uri: `/main?language_id=${languageId}`
    })
  );

  const { companySustainable = {}, irSchedule = {}, irEarning = {}, mediaNewsArticle = {}, mediaNewsPress = {} } = data.reduce(function(
    acc: any,
    cur: any
  ) {
    acc[cur.mainType] = cur;
    return acc;
  },
  {});

  return (
    <Wrapper>
      <article>
        <h2>{t('home.ir')}</h2>
        <section className="ir">
          {/* <header className="ir">
            <span onClick={() => navigate('/ir/schedule')}>{t('submenu.schedule')}</span>
            <hr style={{ backgroundColor: '#1A1A1A' }} />
          </header>
          {isEmptyObject(irSchedule) ? (
            <Skeleton width={'100%'} height={150} styles={{ marginTop: 16 }} />
          ) : (
            <>
              {irSchedule.items.map((item: any, i: number) => (
                <div className="clickable ir" onClick={() => navigate('/ir/schedule')} key={item.id}>
                  <p className="title">{item.title_t}</p>
                  <p>
                    <span>
                      {`${
                        dateHelper(item.start_at) === dateHelper(item.end_at)
                          ? dateHelper(item.start_at)
                          : dateHelper(item.start_at) + ' ~ ' + dateHelper(item.end_at)
                      }
                `}
                    </span>
                    <span>
                      {t('location')} : {item.place_t}
                    </span>
                  </p>
                  <hr />
                </div>
              ))}
            </>
          )} */}
          <header className="ir">
            <span className="irHead" onClick={() => navigate('/ir/earning')}>
              {t('submenu.earning')}
            </span>
            <span className="more" onClick={() => navigate('/ir/earning')}>
              {t('more')}
            </span>
            <hr style={{ backgroundColor: '#1A1A1A' }} />
          </header>
          {isEmptyObject(irEarning) ? (
            <Skeleton width={'100%'} height={150} styles={{ marginTop: 16 }} />
          ) : (
            <>
              {irEarning.items.map((item: any, i: number) => (
                <div className="clickable ir" onClick={() => navigate('/ir/earning')} key={item.id}>
                  <p className="title">{item.title_t}</p>
                  <hr />
                </div>
              ))}
            </>
          )}
        </section>
        <section className="company">
          <header>
            <span onClick={() => navigate('/company/sustainable-management')}>{t('submenu.sustainable-management')}</span>
          </header>
          <div className=" contents">
            <div className="thumbnailWrapper clickable" onClick={() => navigate('/company/sustainable-management/partnership')}>
              <LongThumbnail imageUrl={imageUrl.mainPartnership} style={{ marginBottom: 0 }}>
                <p>{t('subsubmenu.partnership')}</p>
              </LongThumbnail>
            </div>
            <div className="thumbnailWrapper clickable" onClick={() => navigate('/company/sustainable-management/environment')}>
              <LongThumbnail imageUrl={imageUrl.mainEnvironment} style={{ marginBottom: 0 }}>
                <p>{t('subsubmenu.environment')}</p>
              </LongThumbnail>
            </div>
            <div className="thumbnailWrapper clickable" onClick={() => navigate('/company/sustainable-management/social')}>
              <LongThumbnail imageUrl={imageUrl.mainSocial} style={{ marginBottom: 0 }}>
                <p>{t('subsubmenu.social')}</p>
              </LongThumbnail>
            </div>
          </div>
        </section>
      </article>
      <article>
        <h2>{t('menu.media')}</h2>
        <section className="media press">
          {isEmptyObject(mediaNewsPress) ? (
            <Skeleton width={'100%'} height={150} styles={{ marginTop: 16 }} />
          ) : (
            <div className="clickable media" onClick={() => navigate(`/media/news-room/press/${mediaNewsPress.id}`)}>
              <Thumbnail imageUrl={encodeFileUrl(mediaNewsPress.thumbnail_url)} />
              <header>
                <span>{t('subsubmenu.press')}</span>
              </header>
              <p style={{ marginTop: 12, wordBreak: 'keep-all' }}>{mediaNewsPress.title_t}</p>
              <p style={{ wordBreak: 'keep-all' }}>
                <span>{dateHelper(mediaNewsPress.datetime)}</span>
              </p>
            </div>
          )}
        </section>
        <section className="media">
          {isEmptyObject(mediaNewsArticle) ? (
            <Skeleton width={'100%'} height={150} styles={{ marginTop: 16 }} />
          ) : (
            <div className="clickable media" onClick={() => navigate(`/media/news-room/article/${mediaNewsArticle.id}`)}>
              <Thumbnail imageUrl={encodeFileUrl(mediaNewsArticle.thumbnail_url)} />
              <header>
                <span>{t('subsubmenu.article')}</span>
              </header>
              <p style={{ marginTop: 12, wordBreak: 'keep-all' }}>{mediaNewsArticle.title_t}</p>
              <p style={{ wordBreak: 'keep-all' }}>
                <span>{dateHelper(mediaNewsArticle.datetime)}</span>
              </p>
            </div>
          )}
        </section>
      </article>
    </Wrapper>
  );
};

export default Home;
