import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiAxios } from '../../helpers/axios';
import { dateHelper, getNameFromBucketUrl } from '../../helpers';
import DownloadIcon from '../../assets/images/Download.png';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  max-width: calc(1000px + 32px);
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  h3 {
    margin: 0px 0px 12px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }

  .datetime {
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #999999;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 0px;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }

  button.goBack {
    padding: 14px 24px;
    border: 1px solid #1a1a1a;
    align-self: flex-start;
  }

  @media screen and (max-width: 992px) {
    h3 {
      width: calc(100% - 40px);
      word-break: keep-all;
      text-align: center;
    }
  }
`;

export const HTMLContent = styled.div`
  width: 100%;
  margin: 42px 0px 80px 0px;

  * {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  table {
    width: 100%;

    border: 1px solid #444444;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 8px;
    text-align: unset;
    border: 1px solid #444444;
  }

  @media screen and (max-width: 992px) {
    box-sizing: border-box;
    padding: 0px 16px;
    margin: 24px 0px 80px 0px;
  }
`;

const ReportHTMLContent = styled(HTMLContent)`
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 12px;

    strong {
      font-weight: 700;
      font-size: 24px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;

    strong {
      font-weight: bold;
    }
  }

  img {
    max-width: 100%;
    width: unset;
  }
`;

const FileWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #1a1a1a;
  border-bottom: 1px solid #999999;
  margin-bottom: 32px;
  font-size: 14px;

  .fileItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 28px 0px 30px;
  }

  @media screen and (max-width: 992px) {
    box-sizing: border-box;
    padding: 0px 16px;

    .fileItem {
      margin: 24px 0px 26px;

      span {
        width: calc(100% - 100px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;

const ReportDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, isError, data: report } = useQuery('irBoards', () =>
    apiAxios({
      method: 'GET',
      uri: `/ir/boards/${id}`
    })
  );

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/ir/report" />;
  }

  return (
    <Wrapper>
      <h3>{report.title_t}</h3>
      <span className="datetime">{dateHelper(report.datetime, '-')}</span>
      <ReportHTMLContent dangerouslySetInnerHTML={{ __html: report.content_t }} />
      {report.file_urls_t && (
        <FileWrapper>
          {report.file_urls_t.map(
            (file: string, index: number) =>
              file && (
                <div className="fileItem" key={index}>
                  <span>{getNameFromBucketUrl(file)}</span>
                  <button onClick={() => window.open(file, '_blank')}>
                    {t('download')}
                    <img src={DownloadIcon} alt="download" />
                  </button>
                </div>
              )
          )}
        </FileWrapper>
      )}
      <button className="goBack" onClick={() => navigate('/ir/report')}>
        목록
      </button>
    </Wrapper>
  );
};

export default ReportDetail;
