import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { apiAxios } from '../../helpers/axios';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import StringDropDownItem from '../../components/DropDown/StringDropDown';
import FileDropDownItem from '../../components/DropDown/FileDropDown';
import DownloadIcon from '../../assets/images/Download.png';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { getNameFromBucketUrl, isKoreanLanguage } from '../../helpers';

interface TableRowInterface {
  title_t: string;
  file_urls_t: string[];
  year_no: number;
  quarter_no: number;
}

interface SortedDataInterface {
  [year: string]: any; // index signature
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;

    .dropdownWrapper {
      margin-top: 30px;
      width: 110px;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .dropdownWrapper {
        margin-top: 0px;
        width: 110px;

        .parentWrapper {
          padding: 0px;
        }

        .childrenWrapper {
          top: 0px;
          left: unset;
          right: 0px;
          border-bottom: none;
          background: #f9f8f9;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
          width: 150px;
          z-index: 990;

          & > div {
            margin: 24px 10px;
            color: #1a1a1a;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    height: 64px;
    border-bottom: 1px solid #999999;

    & > * {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .title,
    .download {
      flex: 1;
      a {
        margin-left: 12px;
        color: #1a1a1a;
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }

    .download {
      a:first-child {
        margin-left: 0px;
      }
      button.first {
        margin-left: 0px;
      }
    }
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    font-size: 12px;
    padding: 8px 8px;
    border: 1px solid #1a1a1a;
    width: 150px;

    span {
      width: 100%;
    }

    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
  }

  button.none {
    pointer-events: none;
    border: none;
  }

  @media screen and (max-width: 1080px) {
    .row.header {
      display: none;
    }
  }
`;

const PCTableSection = styled(TableSection)`
  display: block;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const MobileTableSection = styled(TableSection)`
  display: none;

  @media screen and (max-width: 1080px) {
    display: block;

    .childrenWrapper {
      z-index: 990;

      button.subLink {
        font-size: 14px;
        width: 100%;
        height: 44px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      & > div {
        margin: 0px 0px 16px 0px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
`;

const Earning = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const [sortedData, setSortedData] = useState<SortedDataInterface>({});
  const [pinnedData, setPinnedData] = useState<TableRowInterface[]>([]);
  const [selectedYear, setSelectedYear] = useState('');

  const isMobile = useMediaQuery({ maxWidth: 1080 });

  // 순서 3: 연도별로 나뉘어진 sortedData의 연도값(year: number)만 추출해서 내림차순 정렬
  const scheduledYears = useMemo<string[]>(() => Object.keys(sortedData).reverse(), [sortedData]);

  // 순서 1: API fetching
  const { isLoading, isError, data = {} } = useQuery(['irEarnings', languageId], () =>
    apiAxios({
      method: 'GET',
      uri: `/ir/earnings?language_id=${languageId}&limit=1000`
    })
  );

  // 순서 2: API 에서 받은 배열 데이터를 연도/분기 기준으로 정렬하고 연도별로 나누어서 sortedData에 셋팅
  useEffect(() => {
    const { unpinned_items = [], pinned_items = [] } = data;

    if (!unpinned_items?.length) {
      setSortedData({});
    } else {
      let _sortedData: SortedDataInterface = {};
      unpinned_items
        .sort((a: TableRowInterface, b: TableRowInterface) => b.year_no - a.year_no || b.quarter_no - a.quarter_no)
        .forEach(async (item: TableRowInterface) => {
          _sortedData = Object.assign(_sortedData, { [item.year_no]: [...(_sortedData[item.year_no] || []), item] });
        });

      setSortedData({ ..._sortedData });
    }

    if (!pinned_items?.length) {
      setPinnedData([]);
    } else {
      setPinnedData(pinned_items);
    }
  }, [data]);

  // 순서 4: 배열 연도값(year[])이 설정된 직후에 selectedYear 초기값 셋팅
  useEffect(() => {
    if (scheduledYears.length === 0) return;
    setSelectedYear(scheduledYears[0]);
  }, [scheduledYears]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleClickYearDropdown = (value: string) => {
    setSelectedYear(value);
  };

  const handleClickFileDropdown = (value: string) => {
    value && window.open(value, '_blank');
  };

  return (
    <Wrapper>
      <header>
        {t('ir.earnings.h1')}
        <div className="dropdownWrapper">
          <StringDropDownItem name={`${selectedYear}${t('year')}`} children={scheduledYears} handleClick={handleClickYearDropdown} />
        </div>
      </header>
      <PCTableSection>
        <div className="row header">
          <span className="title">{t('title2')}</span>
          <span className="download">{t('download')}</span>
        </div>
        {pinnedData &&
          pinnedData.map((item: TableRowInterface, index: number) => (
            <div className="row" key={index}>
              <span className="title">{item.title_t}</span>
              <div className="download">
                {item?.file_urls_t && item?.file_urls_t[0] ? (
                  <a href={item.file_urls_t[0] || ''} target="_blank" rel="noreferrer">
                    <button>
                      <span>{t('report2')}</span>
                      <img src={DownloadIcon} alt="download" />
                    </button>
                  </a>
                ) : (
                  !item?.file_urls_t[1] && (
                    <button className="first none">
                      <span>{'-'}</span>
                    </button>
                  )
                )}

                {item?.file_urls_t && item?.file_urls_t[1] ? (
                  <a href={item.file_urls_t[1] || ''} target="_blank" rel="noreferrer">
                    <button>
                      <span>{'Excel'}</span>
                      <img src={DownloadIcon} alt="download" />
                    </button>
                  </a>
                ) : (
                  !item?.file_urls_t[0] && (
                    <button className="none">
                      <span>{'-'}</span>
                    </button>
                  )
                )}
              </div>
            </div>
          ))}
        {sortedData[selectedYear] &&
          sortedData[selectedYear].map((item: TableRowInterface, index: number) => (
            <div className="row" key={index}>
              <span className="title">{item.title_t}</span>
              <div className="download">
                {item?.file_urls_t && item?.file_urls_t[0] ? (
                  <a href={item.file_urls_t[0] || ''} target="_blank" rel="noreferrer">
                    <button>
                      <span>{t('report2')}</span>
                      <img src={DownloadIcon} alt="download" />
                    </button>
                  </a>
                ) : (
                  !item?.file_urls_t[1] && (
                    <button className="first none">
                      <span>{'-'}</span>
                    </button>
                  )
                )}

                {item?.file_urls_t && item?.file_urls_t[1] ? (
                  <a href={item.file_urls_t[1] || ''} target="_blank" rel="noreferrer">
                    <button>
                      <span>{'Excel'}</span>
                      <img src={DownloadIcon} alt="download" />
                    </button>
                  </a>
                ) : (
                  !item?.file_urls_t[0] && (
                    <button className="none">
                      <span>{'-'}</span>
                    </button>
                  )
                )}
              </div>
            </div>
          ))}
      </PCTableSection>
      <MobileTableSection>
        {pinnedData &&
          pinnedData.map((item: TableRowInterface) => (
            <FileDropDownItem key={item?.title_t} name={item?.title_t} children={item?.file_urls_t} handleClick={handleClickFileDropdown} />
          ))}
        {sortedData[selectedYear] &&
          sortedData[selectedYear].map((item: TableRowInterface) => (
            <FileDropDownItem key={item?.title_t} name={item?.title_t} children={item?.file_urls_t} handleClick={handleClickFileDropdown} />
          ))}
      </MobileTableSection>
    </Wrapper>
  );
};

export default Earning;
