import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { imageUrl } from '../../assets/images/urls';

interface StyledBannerInterface {
  imageUrl?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  max-width: 1080px;
  position: relative;

  h2 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin: 32px 0px 12px;
  }

  h3 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin: 32px 0px 12px;
  }

  p {
    white-space: pre-line;
    word-break: keep-all;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin: 0px 0px 0px 0px;
  }

  @media screen and (max-width: 992px) {
    header {
      margin: 20px 0px 24px;
    }

    img {
      height: 380px;
      object-fit: cover;
    }
  }
`;

const ImageWrapper = styled.div<StyledBannerInterface>`
  width: 100%;
  height: 380px;
  background-size: cover;
  background-position: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  display: flex;

  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;

    letter-spacing: -0.02em;
    color: #ffffff;
  }

  @media screen and (max-width: 992px) {
    header {
      margin: 20px 0px 24px;
    }

    height: 380px;
    object-fit: cover;
  }
`;

const CardWrapper = styled.div<StyledBannerInterface>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 48px 0 80px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #1a1a1a;

  .card {
    width: 100%;
    height: 171px;
    background: #eeeeee;
    border-radius: 10px;
    margin-right: 16px;

    h1 {
      padding: 0 0 0 32px;
      margin: 12px 0 0 0;
      font-size: 36px;
      line-height: 100%;
    }

    h3 {
      margin: 0;
      padding: 32px 0 0 32px;
      font-size: 16px;
      line-height: 150%;
      color: #999999;
    }

    h4 {
      margin: 12px 0 0 0;
      padding: 0 0 0 32px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
    }
  }

  .card:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;

    .card:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

const TextWrapper = styled.div`
  @media screen and (max-width: 992px) {
    padding: 0 16px;
  }
`;

const Introduction = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImageWrapper imageUrl={imageUrl.introduction1} />
      <TextWrapper>
        <h2>{t('company.introduction.h1')}</h2>
        <p>{t('company.introduction.p1')}</p>
        <CardWrapper>
          <div className="card">
            <h3>{t('company.introduction.card.t1')}</h3>
            <h1>{t('company.introduction.card.p1')}</h1>
          </div>
          <div className="card">
            <h3>{t('company.introduction.card.t2')}</h3>
            <h1>{t('company.introduction.card.p2')}</h1>
          </div>
          <div className="card">
            <h3>{t('company.introduction.card.t3')}</h3>
            <h1>{t('company.introduction.card.p3')}</h1>
            <h4>{t('company.introduction.card.pp3')}</h4>
          </div>
        </CardWrapper>
      </TextWrapper>
      <ImageWrapper imageUrl={imageUrl.introduction2}>{/* <h1>{t('company.introduction.h2')}</h1> */}</ImageWrapper>
      <TextWrapper>
        <h3>{t('company.introduction.t2')}</h3>
        <p>{t('company.introduction.p2')}</p>
        <h3>{t('company.introduction.t3')}</h3>
        <p>{t('company.introduction.p3')}</p>
        <h3>{t('company.introduction.t4')}</h3>
        <p>{t('company.introduction.p4')}</p>
        <h3>{t('company.introduction.t5')}</h3>
        <p style={{ wordBreak: 'normal' }}>{t('company.introduction.p5')}</p>
      </TextWrapper>
    </Wrapper>
  );
};

export default Introduction;
