import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { fadeAnimation } from '../../assets/styles';
import { EN_HIDDEN_LIST, INACTIVE_LIST, IS_NEWS_URL, MENU } from '../../helpers/constants';
import styled from 'styled-components';
import DropDownItem from '../DropDown';
import HomeLogo from '../../assets/images/HomeLogo.svg';
import CloseIcon from '../../assets/images/Close.png';
import MenuIcon from '../../assets/images/Menu.png';
import { isKoreanLanguage } from '../../helpers';

interface MobileNavWrapperProps {
  isDisplayMobileNav: boolean;
}

interface NavWrapperProps {
  lang?: string;
  leftMargin?: string;
}

interface SubMenuInterface {
  name: string;
  to: string;
  subSubMenuList: [SubSubMenuInterface];
}

interface SubSubMenuInterface {
  name: string;
  key: string;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;

  button,
  a {
    background: none;
    border: none;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

const NavWrapper = styled.header<NavWrapperProps>`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ece6e2;

  .pcLinkWrapper {
    position: absolute;

    left: ${({ lang }) => (lang === 'en' ? ' calc(50% - 350px + 64px)' : ' calc(50% - 186px)')};

    .navItem {
      font-size: 16px;
      margin-right: 46px;
      font-weight: bold;
    }

    .navItem.active {
      font-weight: bold;
      color: #5f0080;
    }
  }

  .pcLanguageWrapper {
    margin-left: 80px;

    .languageItem {
      color: #999999;
      font-size: 16px;
    }

    .languageItem:first-child {
      margin-right: 24px;
    }

    .languageItem.active {
      color: #1a1a1a;
    }
  }

  .mobileNavButton {
    display: none;

    img {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (max-width: 1210px) {
    .pcLinkWrapper,
    .pcLanguageWrapper {
      display: none;
    }

    .mobileNavButton {
      display: block;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: calc(1320px + 32px);
  padding: 0px 16px;
  box-sizing: border-box;
`;

const NavContent = styled(Content)`
  height: 116px;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 1210px) {
    height: 50px;
  }
`;

const SubNavContent = styled(Content)<NavWrapperProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin: ${({ lang }) => (lang === 'en' ? '0' : 'auto')};
  ${({ lang, leftMargin }) => lang === 'en' && `margin-left: calc((100vw - 1320px)/2 + ${leftMargin})`};

  & > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 70px;
  }

  div:last-child {
    margin-right: 0px;
  }

  a {
    font-family: Noto Sans KR;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #1a1a1a;
    font-weight: 700;
    margin-bottom: 1;
  }

  a.hasSubSub {
    margin-bottom: 24px;
  }

  a.subSub {
    margin-bottom: 16px;
    font-weight: 400;
  }
`;

const StyledHomeLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: 16px;

  img {
    width: 116px;
  }

  h2 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin-left: 12px;
  }

  @media screen and (max-width: 1210px) {
    img {
      width: 60px;
    }

    h2 {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #1a1a1a;
    }
  }
`;

const PcSubNavWarpper = styled.div<NavWrapperProps>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ lang }) => (lang === 'en' ? 'start' : 'center')};
  padding: 30px 0px 30px;
  position: absolute;
  background: white;
  width: 100%;
  top: 117px;
  z-index: 9999;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

  ${fadeAnimation}
`;

const MobileNavWrapper = styled.div<MobileNavWrapperProps>`
  position: fixed;
  top: 51px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: white;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 40px 16px;
  z-index: 9999;
  overflow-y: scroll;
  box-sizing: border-box;
  display: ${({ isDisplayMobileNav }) => (isDisplayMobileNav ? 'block' : 'none')};
  height: calc(100% - 50px);

  .mobileLanguageWrapper {
    justify-content: flex-start;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    margin-top: 80px;

    button {
      padding: 0px;
      margin-right: 16px;
      color: #999999;
    }

    button.active {
      color: #1a1a1a;
    }
  }

  .mobileFooter {
    display: block;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #999999;
    margin-top: 32px;
  }

  @media screen and (min-width: 1211px) {
    display: none;
  }
`;

type MarginType = {
  [index: string]: string;
  home: string;
  stock: string;
};

const EngNavMargin: MarginType = {
  home: '0px',
  company: '210px',
  finance: '486px',
  stock: '526px',
  ir: '678px',
  media: '820px'
};

const Nav = () => {
  const activeClassName = 'active';

  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const [focusedMenu, setFocusedMenu] = useState('home');
  const [isDisplaySubNav, setIsDisplaySubNav] = useState(false);
  const [isDisplayMobileNav, _setIsDisplayMobileNav] = useState(false);

  const subMenuList = useMemo(() => MENU[focusedMenu].subMenuList || [], [focusedMenu]);

  const firstPathName = pathname.split('/')[1];
  const isMain = firstPathName === '';
  const isMediaMenu = firstPathName.includes('media');

  const onChangeLang = (lang: string) => {
    if (!IS_NEWS_URL) {
      i18n.changeLanguage(lang);
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<Element, MouseEvent>, menuValue: string) => {
    // console.log(e.nativeEvent.offsetX);
    setFocusedMenu(menuValue);
    setIsDisplaySubNav(true);
  };

  const handleMouseLeave = () => {
    setIsDisplaySubNav(false);
    setFocusedMenu('home');
  };

  const setIsDisplayMobileNav = (changed = !isDisplayMobileNav) => {
    document.body.style.position = changed ? 'fixed' : 'relative';
    _setIsDisplayMobileNav(changed);
  };

  const handleClickMobileSub = () => {
    setIsDisplayMobileNav(false);
  };

  return (
    <Wrapper onMouseLeave={handleMouseLeave}>
      <NavWrapper lang={i18n.language}>
        <NavContent>
          <StyledHomeLink to={IS_NEWS_URL ? '/redirect' : '/'} state={{ path: '' }} onClick={() => setIsDisplayMobileNav(false)}>
            <img src={HomeLogo} alt="logo" />
            {isMain ? <></> : isMediaMenu ? <h2>Media Center</h2> : <h2>Investor Relations</h2>}
          </StyledHomeLink>
          <div className="pcLinkWrapper">
            {/* <NavLink
              to="/"
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => handleMouseEnter(e, 'home')}
            >
              HOME
            </NavLink> */}
            <NavLink
              to={IS_NEWS_URL ? '/redirect' : 'company/introduction'}
              state={{ path: 'company/introduction' }}
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => {
                handleMouseEnter(e, 'company');
              }}
            >
              {t('menu.company')}
            </NavLink>
            <NavLink
              to={IS_NEWS_URL ? '/redirect' : 'finance/summary/consolidated'}
              state={{ path: 'finance/summary/consolidated' }}
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => handleMouseEnter(e, 'finance')}
            >
              {t('menu.finance')}
            </NavLink>
            {/* <NavLink
              to={IS_NEWS_URL ? '/redirect' : 'stock/owner-structure'}
              state={{ path: 'stock/owner-structure' }}
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => handleMouseEnter(e, 'stock')}
            >
              {t('menu.stock')}
            </NavLink> */}
            <NavLink
              to={IS_NEWS_URL ? '/redirect' : 'ir/earning'}
              state={{ path: 'ir/earning' }}
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => handleMouseEnter(e, 'ir')}
            >
              {t('menu.ir')}
            </NavLink>
            <NavLink
              to={IS_NEWS_URL ? '/redirect' : 'media/news-room/press'}
              state={{ path: 'media/news-room/press' }}
              className={({ isActive }) => `navItem ${isActive ? activeClassName : undefined}`}
              onMouseEnter={e => handleMouseEnter(e, 'media')}
            >
              {t('menu.media')}
            </NavLink>
          </div>
          <div className="pcLanguageWrapper">
            {/* <button className={`languageItem ${i18n.language === 'ko' ? activeClassName : undefined}`} onClick={() => onChangeLang('ko')}>
              KOR
            </button>
            <button className={`languageItem ${i18n.language === 'en' ? activeClassName : undefined}`} onClick={() => onChangeLang('en')}>
              ENG
            </button> */}
          </div>
          <div className="mobileNavButton" onClick={() => setIsDisplayMobileNav(!isDisplayMobileNav)}>
            {isDisplayMobileNav ? <img src={CloseIcon} alt="logo" /> : <img src={MenuIcon} alt="logo" />}
          </div>
        </NavContent>
      </NavWrapper>
      <PcSubNavWarpper className={isDisplaySubNav && focusedMenu !== 'home' ? 'fadeIn' : 'fadeOut'} lang={i18n.language}>
        <SubNavContent lang={i18n.language} leftMargin={EngNavMargin[focusedMenu]}>
          {subMenuList.map((menu: SubMenuInterface, index: number) => {
            const subSubMenuList = menu?.subSubMenuList || [];
            const hasSubSub = subSubMenuList.length > 0;
            const isHidden = i18n.language === 'en' && EN_HIDDEN_LIST.includes(menu.to);
            const isInactive = INACTIVE_LIST.includes(menu.to);
            return (
              !isHidden && (
                <div key={index}>
                  <Link
                    className={`${hasSubSub ? 'hasSubSub' : ''} ${isInactive ? 'inactive' : ''}`}
                    to={IS_NEWS_URL ? '/redirect' : `${focusedMenu}/${menu.to}${hasSubSub ? '/' + subSubMenuList[0].key : ''}`}
                    state={{ path: `${focusedMenu}/${menu.to}${hasSubSub ? '/' + subSubMenuList[0].key : ''}` }}
                  >
                    {t(`submenu.${menu.to}`)}
                  </Link>
                  {hasSubSub &&
                    subSubMenuList.map((subMenu: SubSubMenuInterface, index: number) => {
                      return (
                        <Link
                          className={`subSub ${INACTIVE_LIST.includes(subMenu.key) ? 'inactive' : ''}`}
                          to={IS_NEWS_URL ? '/redirect' : `${focusedMenu}/${menu.to}/${subMenu.key}`}
                          key={index}
                          state={{ path: `${focusedMenu}/${menu.to}/${subMenu.key}` }}
                        >
                          {t(`subsubmenu.${subMenu.key}`)}
                        </Link>
                      );
                    })}
                </div>
              )
            );
          })}
        </SubNavContent>
      </PcSubNavWarpper>
      <MobileNavWrapper isDisplayMobileNav={isDisplayMobileNav}>
        <DropDownItem name={t('menu.company')} to="company" children={MENU['company'].subMenuList} handleClick={handleClickMobileSub} />
        <DropDownItem name={t('menu.finance')} to="finance" children={MENU['finance'].subMenuList} handleClick={handleClickMobileSub} />
        {/* <DropDownItem name={t('menu.stock')} to="stock" children={MENU['stock'].subMenuList} handleClick={handleClickMobileSub} /> */}
        <DropDownItem name={t('menu.ir')} to="ir" children={MENU['ir'].subMenuList} handleClick={handleClickMobileSub} />
        <DropDownItem name={t('menu.media')} to="media" children={MENU['media'].subMenuList} handleClick={handleClickMobileSub} />
        <div className="mobileLanguageWrapper">
          {/* <button className={`languageItem ${i18n.language === 'ko' ? activeClassName : undefined}`} onClick={() => onChangeLang('ko')}>
            KOREAN
          </button>
          <button
            className={`languageItem ${!isKoreanLanguage(i18n.language) ? activeClassName : undefined}`}
            onClick={() => onChangeLang('en')}
          >
            ENGLISH
          </button> */}
        </div>
        {/* <span className="mobileFooter">{'Copyright © Kakao Corp. All rights reserved.'}</span> */}
      </MobileNavWrapper>
    </Wrapper>
  );
};

export default Nav;
