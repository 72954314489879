import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useMemo, useState } from 'react';
import { apiAxios } from '../../helpers/axios';
import Skeleton from '../../components/Skeleton';
import NewsRoomBoard from './NewsRoomBoard';
import { useSubSubMenu } from '../../context/router';
import { dateHelper, encodeFileUrl, isKoreanLanguage } from '../../helpers';
import { useTranslation } from 'react-i18next';

import MoreButton from '../../components/Pagination/Cursor';
import PaginationBar from '../../components/Pagination/OffSet';

interface GridListInterface {
  data: Array<GridItemInterface>;
  subSubKey: string;
}

interface GridItemInterface {
  id: number;
  title_t: string;
  datetime: string;
  content_t?: string;
  thumbnail_url?: string;
  file_urls_t?: any;
  article_urlt?: string;
  submenu: string;
}

interface StyledCardInterface {
  imageUrl?: string;
}

interface StyledWrapperInterface {
  submenu: string;
}

const Wrapper = styled.div<StyledWrapperInterface>`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 992px) {
    margin-top: ${({ submenu }) => (submenu === 'notice' ? '0px' : '30px')};
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 32px 24px;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div<StyledCardInterface>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  * {
    font-family: 'Noto Sans KR';
  }

  .thumbnail {
    width: 100%;
    padding-bottom: 85%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: #e4e4e4;

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    margin: 18px 0 14px;

    button {
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 150%;
    }
  }

  .summary {
    width: 100%;
    margin: 0px 0px 18px 0px;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #999999;

    // multiline text ellipsis styles
    line-height: 21px;
    max-height: 63px;
    overflow: hidden;
    word-break: keep-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .datetime {
    font-size: 14px;
    line-height: 150%;
    color: #999999;
  }

  @media screen and (max-width: 576px) {
    h3 {
      font-size: 16px;
    }

    .datetime {
      font-size: 12px;
    }
  }
`;

const GridList = ({ data, subSubKey }: GridListInterface) => {
  const navigate = useNavigate();

  return (
    <Grid>
      {data.map((item: GridItemInterface, index: number) => {
        const summary = item.content_t ? item.content_t.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, '') : '';
        return (
          <Card
            key={index}
            imageUrl={encodeFileUrl(item.thumbnail_url)}
            onClick={() => navigate(`/media/news-room/${subSubKey}/${item.id}`)}
          >
            <div className="thumbnail" />
            <h3>
              <button>{item.title_t}</button>
            </h3>
            {summary && <div className="summary">{summary}</div>}
            <span className="datetime">{dateHelper(item.datetime, '-')}</span>
          </Card>
        );
      })}
    </Grid>
  );
};

const NewsRoom = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { subSubKey } = useSubSubMenu();
  const [itemList, setItemList] = useState<GridItemInterface[]>([]);
  const itemPerPage = isMobile ? 6 : 9;

  useEffect(() => {
    setPage(1);
    setItemList([]);
  }, [subSubKey]);

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['mediaNews', subSubKey, languageId, page],
    () => {
      if (['press', 'article'].includes(subSubKey)) {
        return apiAxios({
          method: 'GET',
          uri: `/media/news?submenu=${subSubKey}&language_id=${languageId}&limit=${itemPerPage}&page=${page}`
        });
      } else {
        return apiAxios({
          method: 'GET',
          uri: `/media/news?submenu=${subSubKey}&language_id=${languageId}&limit=10000`
        });
      }
    },
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.items) {
      if (['press', 'article'].includes(subSubKey)) {
        if (page === 1) {
          setItemList(data.items);
        } else {
          if (data.items.length > 0) setItemList(prevItemList => prevItemList.concat(data.items));
        }
      } else {
        setItemList(data.items);
      }
    }
    setTotal(data.total);
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleNext = () => {
    if (page < Math.ceil(total / itemPerPage)) setPage(prevPage => prevPage + 1);
  };

  const handlePrev = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  const handleClickPage = (page: number) => {
    setPage(page);
  };

  return (
    <Wrapper submenu={subSubKey}>
      {['press', 'article'].includes(subSubKey) ? <GridList data={itemList} subSubKey={subSubKey} /> : <NewsRoomBoard data={itemList} />}
      {['press', 'article'].includes(subSubKey) && !isFetching && total > itemList.length && <MoreButton onClickHandler={handleMore} />}
    </Wrapper>
  );
};

export default NewsRoom;
