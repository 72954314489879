import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { imageUrl } from '../../assets/images/urls';

interface StyledCardInterface {
  imageUrl?: string;
  contentWidth?: string;
}

export const SustainableManagementWrapper = styled.article`
  display: flex;
  flex-direction: column;

  max-width: 1080px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
  color: #1a1a1a;

  img {
    width: 100%;
  }

  header {
    line-height: 150%;
    font-weight: 700;
    font-size: 24px;
    margin-left: 92px;
  }

  section {
    padding-top: 120px;
  }

  @media screen and (max-width: 992px) {
    header,
    p,
    a {
      padding: 0 16px;
      margin: 0;
    }

    p {
      padding-top: 22px;
    }

    section {
      padding-top: 80px;
      white-space: normal;
    }
  }

  @media screen and (max-width: 576px) {
    img {
      width: 100vw;
      height: 100vw;
      object-fit: cover;
    }

    section {
      padding-top: 80px;
    }
  }
`;

export const SustainableManagementHeader = styled.section`
  display: flex;
  flex-direction: row;
  padding: 32px 0px;

  header {
    line-height: 150%;
    font-weight: 700;
    font-size: 24px;
    margin-left: 53px;

    span {
      white-space: pre;
    }

    .pcBreak {
      display: block;
    }
  }

  p {
    margin: auto;
    text-align: center;
  }

  p.en {
    margin-left: 66px;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;

    header,
    p {
      margin: 0;
      .pcBreak {
        display: none;
      }
    }

    header {
      span {
        white-space: normal;
      }
    }

    p.en {
      margin: 0;
    }
  }
`;

export const SustainableManagementCard = styled.div<StyledCardInterface>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  margin-left: 92px;
  margin-top: 32px;

  line-height: 150%;
  font-size: 14px;
  align-items: center;

  .content {
    width: 371px;
    margin-left: 42px;
    align-self: center;

    header {
      margin-left: 0;
      font-size: 16px;
      min-width: 300px;
      width: ${({ contentWidth }) => contentWidth};
      white-space: pre-wrap;
    }

    p {
      padding-top: 16px;
      white-space: pre-line;
      word-break: keep-all;
      margin: 0;
      width: ${({ contentWidth }) => contentWidth};
    }

    a {
      display: inline-block;
      padding-top: 24px;
      color: #5f0080;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .thumbnail {
    width: 428px;
    height: 238px;
    background-position: center;

    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: #e4e4e4;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin: 0;
    padding-top: 32px;

    .thumbnail {
      width: 100vw;
    }

    .content {
      width: 100vw;
      margin: 0;
      align-self: center;

      header {
        padding-top: 24px;
        width: unset;
        min-width: unset;
      }

      a {
        padding-bottom: 23px;
      }

      p {
        width: unset;
      }
    }
  }
`;
