import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';

import { useSubSubMenu } from '../../context/router';
import { dateHelper, encodeFileUrl, isKoreanLanguage } from '../../helpers';
import { SustainableManagementWrapper, SustainableManagementHeader, SustainableManagementCard } from './SustainableManagementTemplate';
import Overview from './Overview';
import MoreButton from '../../components/Pagination/Cursor';

import styled from 'styled-components';
import { imageUrl } from '../../assets/images/urls';
import { apiAxios } from '../../helpers/axios';
import Skeleton from '../../components/Skeleton';

interface GridItemInterface {
  id: number;
  title_t: string;
  datetime: string;
  content_t?: string;
  thumbnail_url?: string;
  file_urls_t?: any;
  article_urlt?: string;
}

interface StyledCardInterface {
  imageUrl?: string;
}

const Wrapper = styled.div`
  display: flex;
  padding: 32px 0px;

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    padding-top: 10px;
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 14px 24px;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding-bottom: 64px;

    white-space: pre-line;
    word-break: keep-all;
    margin: 0;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    text-align: center;
    letter-spacing: -0.02em;

    /* Base/Black */

    color: #1a1a1a;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
    p {
      padding: 0px 12px 42px;
    }
  }
`;

const Card = styled.div<StyledCardInterface>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  * {
    font-family: 'Noto Sans KR';
  }

  .thumbnail {
    width: 100%;
    padding-bottom: 59%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: #e4e4e4;

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    margin: 18px 0 14px;

    button {
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 150%;
    }
  }

  .summary {
    width: 100%;
    margin: 0px 0px 18px 0px;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #999999;

    // multiline text ellipsis styles
    line-height: 21px;
    max-height: 63px;
    overflow: hidden;
    word-break: keep-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .datetime {
    font-size: 14px;
    line-height: 150%;
    color: #999999;
  }

  @media screen and (max-width: 576px) {
    h3 {
      font-size: 16px;
    }

    .datetime {
      font-size: 12px;
    }
  }
`;

const GridList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemList, setItemList] = useState<GridItemInterface[]>([]);
  const itemPerPage = isMobile ? 6 : 9;

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['companySustainables', languageId, page],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/company/sustainables?language_id=${languageId}&limit=${itemPerPage}&page=${page}`
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.items) {
      if (page === 1) {
        setItemList(data.items);
      } else {
        if (data.items.length > 0) setItemList(prevItemList => prevItemList.concat(data.items));
      }
    }
    setTotal(data.total);
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <GridWrapper>
      <p>{t('partnership.h3')}</p>
      <Grid>
        {itemList.map((item: GridItemInterface, index: number) => {
          const summary = item.content_t ? item.content_t.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, '') : '';
          return (
            <Card
              key={index}
              imageUrl={encodeFileUrl(item.thumbnail_url)}
              onClick={() => navigate(`/company/sustainable-management/partnership/${item.id}`)}
            >
              <div className="thumbnail" />
              <h3>
                <button>{item.title_t}</button>
              </h3>
              {summary && <div className="summary">{summary}</div>}
            </Card>
          );
        })}
      </Grid>
      {!isFetching && total > itemList.length && <MoreButton onClickHandler={handleMore} />}
    </GridWrapper>
  );
};

const PartnershipEng = () => {
  const { t, i18n } = useTranslation();
  return (
    <SustainableManagementWrapper>
      <img src={imageUrl.partnershipBanner} alt="Banner" />
      <SustainableManagementHeader style={{ padding: '64px 0 0' }}>
        <p className={i18n.language}>{t(`company.partnership.p`)}</p>
      </SustainableManagementHeader>
      <section>
        <header>{t(`company.partnership.section1.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.partnership1} contentWidth="375px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.partnership.section1.content1.h`)}</header>
            <p>{t(`company.partnership.section1.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
      <section>
        <header>{t(`company.partnership.section2.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.partnership2}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.partnership.section2.content1.h`)}</header>
            <p>{t(`company.partnership.section2.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
      <section>
        <header>{t(`company.partnership.section3.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.partnership3}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.partnership.section3.content1.h`)}</header>
            <p>{t(`company.partnership.section3.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
      <section>
        <header>{t(`company.partnership.section4.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.partnership4}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.partnership.section4.content1.h`)}</header>
            <p>{t(`company.partnership.section4.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
    </SustainableManagementWrapper>
  );
};

const Environment = () => {
  const { t, i18n } = useTranslation();
  return (
    <SustainableManagementWrapper>
      <img src={imageUrl.environmentBanner} alt="Banner" />
      <SustainableManagementHeader style={{ padding: '64px 0 0' }}>
        <p className={i18n.language}>{t(`company.environment.p`)}</p>
      </SustainableManagementHeader>
      <section>
        <header>{t(`company.environment.section1.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.environment1} contentWidth="375px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.environment.section1.content1.h`)}</header>
            <p>{t(`company.environment.section1.content1.p`)}</p>
            {t(`company.environment.section1.content1.a`) && (
              <a href="https://www.kurly.com/introduce/sustainable-distribution/product-selection" target="_blank" rel="noreferrer">
                {t(`company.environment.section1.content1.a`)}
              </a>
            )}
          </div>
        </SustainableManagementCard>
        <SustainableManagementCard imageUrl={imageUrl.environment2} contentWidth={'359px'}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.environment.section1.content2.h`)}</header>
            <p>{t(`company.environment.section1.content2.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
      <section>
        <header>{t(`company.environment.section2.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.environment3}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.environment.section2.content1.h`)}</header>
            <p>{t(`company.environment.section2.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
        <SustainableManagementCard imageUrl={imageUrl.environment4} contentWidth="393px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.environment.section2.content2.h`)}</header>
            <p>{t(`company.environment.section2.content2.p`)}</p>
            {t(`company.environment.section2.content2.a`) && (
              <a href="https://www.kurly.com/introduce/sustainable-distribution/packaging-materials" target="_blank" rel="noreferrer">
                {t(`company.environment.section2.content2.a`)}
              </a>
            )}
          </div>
        </SustainableManagementCard>
      </section>
    </SustainableManagementWrapper>
  );
};

const Social = () => {
  const { t, i18n } = useTranslation();
  return (
    <SustainableManagementWrapper>
      <img src={imageUrl.socialBanner} alt="Banner" />
      <SustainableManagementHeader style={{ padding: '64px 0 0' }}>
        <p className={i18n.language}>{t(`company.social.p`)}</p>
      </SustainableManagementHeader>
      <section>
        <header>{t(`company.social.section1.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.social1} contentWidth="357px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.social.section1.content1.h`)}</header>
            <p>{t(`company.social.section1.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
        <SustainableManagementCard imageUrl={imageUrl.social3} contentWidth="359px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.social.section1.content2.h`)}</header>
            <p>{t(`company.social.section1.content2.p`)}</p>
          </div>
        </SustainableManagementCard>
        <SustainableManagementCard imageUrl={imageUrl.social4}>
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.social.section1.content3.h`)}</header>
            <p>{t(`company.social.section1.content3.p`)}</p>
            {t(`company.social.section1.content3.a`) && (
             <a href="https://www.kurly.com/introduce/sustainable-distribution/social-contribution" target="_blank" rel="noreferrer">
                {t(`company.social.section1.content3.a`)}
              </a>
            )}
          </div>
        </SustainableManagementCard>
      </section>
      <section>
        <header>{t(`company.social.section2.h`)}</header>
        <SustainableManagementCard imageUrl={imageUrl.social5} contentWidth="356px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.social.section2.content1.h`)}</header>
            <p>{t(`company.social.section2.content1.p`)}</p>
          </div>
        </SustainableManagementCard>
        <SustainableManagementCard imageUrl={imageUrl.social6} contentWidth="374px">
          <div className="thumbnail" />
          <div className="content">
            <header>{t(`company.social.section2.content2.h`)}</header>
            <p>{t(`company.social.section2.content2.p`)}</p>
          </div>
        </SustainableManagementCard>
      </section>
    </SustainableManagementWrapper>
  );
};

const SustainableManagement = () => {
  const { subSubKey } = useSubSubMenu();
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      {/* {subSubKey} */}
      {subSubKey === 'overview' && <Overview />}
      {subSubKey === 'partnership' && (isKoreanLanguage(i18n.language) ? <GridList /> : <PartnershipEng />)}
      {subSubKey === 'environment' && <Environment />}
      {subSubKey === 'social' && <Social />}
    </Wrapper>
  );
};

export default SustainableManagement;
