import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { apiAxios } from '../../helpers/axios';
import { useMemo, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import DownloadIcon from '../../assets/images/Download.png';
import styled from 'styled-components';
import Skeleton from '../../components/Skeleton';

import PaginationBar from '../../components/Pagination/OffSet';
import { isKoreanLanguage } from '../../helpers';

interface TableRowInterface {
  year_no: number;
  title_t: string;
  file_url_t: string;
}

interface SortedDataInterface {
  [year: string]: any; // index signature
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    margin-top: 24px;

    header {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    height: 64px;
    border-bottom: 1px solid #999999;

    & > * {
      display: flex;
      flex: 1;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .download {
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #1a1a1a;
      }

      img {
        width: 20px;
        height: 20px;
        margin-left: 6px;
      }
    }
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;
  }

  @media screen and (max-width: 1080px) {
    .row {
      padding: 28px 0px 20px;
      flex-direction: column;
      align-items: flex-start;

      .download {
        align-self: flex-end;
      }
    }

    .row.header {
      display: none;
    }
  }
`;

const AuditReport = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);

  const [itemList, setItemList] = useState<SortedDataInterface>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const itemPerPage = isMobile ? 6 : 9;

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['auditReports', languageId, page],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/company/audits?language_id=${languageId}&page=${page}&limit=${itemPerPage}`
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.items) {
      setItemList(data.items);
      setTotal(data.total);
      window.scrollTo({
        top: 0
      });
    }
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleNext = () => {
    if (page < Math.ceil(total / itemPerPage)) setPage(prevPage => prevPage + 1);
  };

  const handlePrev = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  const handleClickPage = (page: number) => {
    setPage(page);
  };

  return (
    <Wrapper>
      <header>{t('submenu.audit-report')}</header>
      <ContentWrapper>
        <TableSection>
          <div className="row header">
            <span>{t('title2')}</span>
            <span>{t('download2')}</span>
          </div>
          {itemList.map((item: TableRowInterface, index: number) => (
            <div className="row" key={index}>
              <span>{item.title_t}</span>
              <div className="download">
                <a href={item.file_url_t ? item.file_url_t : undefined} target="_blank" rel="noreferrer">
                  {item.file_url_t ? t('pdf') : isMobile ? '' : '-'}
                  {item.file_url_t && <img src={DownloadIcon} alt="download" />}
                </a>
              </div>
            </div>
          ))}
        </TableSection>
        {!isFetching && (
          <PaginationBar
            clickPageHandler={handleClickPage}
            clickNext={handleNext}
            clickPrev={handlePrev}
            pageTotal={total && Math.ceil(total / itemPerPage)}
            currentPage={page}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default AuditReport;
