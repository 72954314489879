import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { imageUrl } from '../../assets/images/urls';
import { IS_NEWS_URL, MENU } from '../../helpers/constants';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { getAllJSDocTagsOfKind } from 'typescript';

interface SubBannerWrapperProps {
  imageName?: string;
}

interface MainBannerWrapperProps {
  imageUrl?: string;
}

const SubBannerWrapper = styled.div<SubBannerWrapperProps>`
  margin-top: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 170px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${({ imageName = '' }) => imageUrl[imageName]});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  h3 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  @media screen and (max-width: 1210px) {
    margin-top: 50px;
  }
`;

const MainBannerWrapper = styled.div`
  margin-top: 117px;
  width: 100%;
  position: relative;

  .slick-dots {
    position absolute;
    bottom: 40px;

    li {
      margin: 0px;
    }

    button::before {
      color: white;
      font-size: 10px;
    }

    li.slick-active button:before {
      color: white;
    }
  }


  h2 {
    position: absolute;
    width: 100%;
  
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    z-index: 998;
    color: white;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    word-break: keep-all;
  }

  @media screen and (max-width: 1210px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 600px) {  
    .slick-dots {
      bottom: 32px;
    }

    h2 {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      white-space: pre-line;
    }
  }
`;

const MainBannerItem = styled.div`
  position: relative;
  height: 470px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    height: 375px;
  }
`;

const MainBannerItemCover = styled.div<MainBannerWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 998;
  background: linear-gradient(0deg, rgba(95, 0, 128, 0.1), rgba(95, 0, 128, 0.1)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  background-color: black;

  h2 {
    position: absolute;
    z-index: 998;
    color: white;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    word-break: keep-all;
    margin: 0px 20px;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }
`;

const MainBanner = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: false,
    appendDots: (dots: any) => (
      <div>
        <ul style={{ margin: '0px', padding: '0px' }}> {dots} </ul>
      </div>
    )
  };

  return (
    <Slider {...settings}>
      <MainBannerItem>
        <MainBannerItemCover imageUrl={imageUrl.mainBanner1}></MainBannerItemCover>
      </MainBannerItem>
      <MainBannerItem>
        <MainBannerItemCover imageUrl={imageUrl.mainBanner2}></MainBannerItemCover>
      </MainBannerItem>
      <MainBannerItem>
        <MainBannerItemCover imageUrl={imageUrl.mainBanner3}></MainBannerItemCover>
      </MainBannerItem>
    </Slider>
  );
};

const Banner = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const firstPathName = IS_NEWS_URL ? 'ir' : pathname.split('/')[1];
  const isMain = firstPathName === '';

  return isMain ? (
    <MainBannerWrapper>
      <h2>{t('banner.first')}</h2>
      <MainBanner />
    </MainBannerWrapper>
  ) : MENU[firstPathName] ? (
    <SubBannerWrapper imageName={MENU[firstPathName].bannerImageName}>
      <h3>{t(`menu.${firstPathName}`)}</h3>
    </SubBannerWrapper>
  ) : (
    <></>
  );
};

export default Banner;
