import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { apiAxios } from '../../helpers/axios';
import ArrowIcon from '../../assets/images/BlackArrow.png';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import PaginationBar from '../../components/Pagination/OffSet';
import { isKoreanLanguage } from '../../helpers';

interface TableRowInterface {
  rcept_dt: string; // 일자
  report_nm: string; // 제목
  rcept_no: string; // 공식뷰어 연결용 번호
  corp_name: string;
  flr_nm: string; // 제출인
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  header {
    width: calc((min(100vw, 1320px) - 768px - 30px) / 2);
    flex-shrink: 0;
    font-weight: 700;
    font-size: 16px;
    color: #1a1a1a;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #1a1a1a;
  font-size: 14px;

  .row {
    height: 64px;
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #999999;

    img {
      display: none;
    }

    & > * {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  .row.header {
    height: 50px;
    border-bottom: 1px solid #1a1a1a;
  }

  .row:not(.header) {
    &:hover {
      cursor: pointer;
    }
  }

  .row {
    .date,
    .by {
      // color: #999999;
    }
  }

  @media screen and (max-width: 1080px) {
    .row {
      height: unset;
      padding: 22px 0px;
      flex-direction: column;
      align-items: flex-start;

      .date {
        order: 2;
        margin-top: 13px;
        color: #999999;
      }

      .title {
        order: 1;
      }

      .by {
        color: #1a1a1a;
        order: 3;
        margin-top: 28px;
      }

      img {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        right: 0px;
        top: 36px;
      }
    }

    .row.header {
      display: none;
    }
  }
`;

const Disclosure = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [dartData, setDartData] = useState<TableRowInterface[]>();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const itemPerPage = isMobile ? 6 : 9;

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['irDisclosure', page],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/ir/disclosure?page=${page}&limit=${itemPerPage}`
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.status !== '000') return;
    setDartData(data.list);
    setTotal(data.total_count);
    window.scrollTo({
      top: 0
    });
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError || !isKoreanLanguage(language)) {
    return <Navigate to="/" />;
  }

  const handleClickRow = (value: string, popupName: string) => {
    const options = 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no';
    window.open(`https://dart.fss.or.kr/dsaf001/main.do?rcpNo=${value}`, popupName, options);
  };

  const handleNext = () => {
    if (page < Math.ceil(total / itemPerPage)) setPage(prevPage => prevPage + 1);
  };

  const handlePrev = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  const handleClickPage = (page: number) => {
    setPage(page);
  };

  return (
    <Wrapper>
      <header>공시</header>
      <ContentWrapper>
        <TableSection>
          <div className="row header">
            <span>일자</span>
            <span>제목</span>
            <span>제출인</span>
          </div>
          {dartData &&
            dartData?.map((item: TableRowInterface, index: number) => (
              <div className="row" key={index} onClick={() => handleClickRow(item?.rcept_no, item?.rcept_dt + item?.report_nm)}>
                <span className="date">
                  {item?.rcept_dt.slice(0, 4) + '-' + item?.rcept_dt.slice(4, 6) + '-' + item?.rcept_dt.slice(6)}
                </span>
                <span className="title">{item?.report_nm}</span>
                <span className="by">{item?.flr_nm}</span>
                <img src={ArrowIcon} alt="arrow" />
              </div>
            ))}
        </TableSection>
        {!isFetching && (
          <PaginationBar
            clickPageHandler={handleClickPage}
            clickNext={handleNext}
            clickPrev={handlePrev}
            pageTotal={total && Math.ceil(total / itemPerPage)}
            currentPage={page}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Disclosure;
