import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ko from './locales/ko.json';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en
      },
      ko: {
        translation: ko
      }
    },
    lng: 'ko', // if you're using a language detector, do not define the lng option
    // fallbackLng: {
    //   en: ['en'],
    //   default: ['ko']
    // },
    // debug: false, //true로 하면 크롬 개발자 도구에 로그가 찍힘
    defaultNS: 'translation',
    ns: ['translation'], // ns = namespace -> label, button, menu 등 구분해서 관리할 경우 필요
    keySeparator: '.',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
