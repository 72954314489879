import { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { Navigate } from 'react-router-dom';
import { apiAxios } from '../../helpers/axios';
import { encodeFileUrl, isKoreanLanguage } from '../../helpers/index';
import { useSubSubMenu } from '../../context/router';
import { dateHelper, getYoutubeId, getYoutubeThumbImageUrl } from '../../helpers';
import DownloadIcon from '../../assets/images/Download.svg';
import CloseIcon from '../../assets/images/Close.svg';
import Skeleton from '../../components/Skeleton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MoreButton from '../../components/Pagination/Cursor';

interface GridItemInterface {
  title_t: string;
  video_url: string;
  thumbnail_url: string;
  datetime: string;
}

interface StyledCardInterface {
  imageUrl?: string;
}

interface StyledMediaDetailInterface {
  submenu: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  // content wrapper css start
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  font-family: 'Noto Sans KR';
  color: #1a1a1a;
  // content wrapper css end

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 576px) {
    margin-top: 10px;
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div<StyledCardInterface>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  * {
    font-family: 'Noto Sans KR';
  }

  .thumbnail {
    width: 100%;
    padding-bottom: 59%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: #e4e4e4;

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    margin: 18px 0 14px;

    button {
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 150%;
    }
  }

  .datetime {
    font-size: 14px;
    line-height: 150%;
    color: #999999;
  }

  @media screen and (max-width: 576px) {
    h3 {
      font-size: 16px;
    }

    .datetime {
      font-size: 12px;
    }
  }
`;

const MediaDetailWrapper = styled.div<StyledMediaDetailInterface>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 9999;

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  iframe {
    min-height: 500px;
  }

  .content {
    max-width: calc(${({ submenu }) => (submenu === 'video' ? '1000px' : '700px')} + 32px);
    box-sizing: border-box;
    padding: 0px 16px;
    margin: calc(117px + (170px / 2 - 20px)) auto; // PC Navigation height + (Banner height / 2 - BannerText height)

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;

      button.close {
        padding: 8px 0px;
        box-sizing: border-box;

        img {
          height: 20px;
          width: 30px;
        }
      }
    }

    .fileContent {
      width: 100%;
      margin-top: 16px;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 980px) {
    iframe {
      min-height: 500px;
    }
  }

  @media screen and (max-width: 576px) {
    overflow-y: hidden;
    iframe {
      min-height: 68vw;
    }
    .content {
      margin: calc(50px + (170px / 2 - 20px)) auto; // Mobile Navigation height + (Banner height / 2 - BannerText height)
    }
  }
`;

const Library = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const languageId = useMemo(() => (isKoreanLanguage(language) ? 1 : 2), [language]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { subSubKey } = useSubSubMenu();
  const [itemList, setItemList] = useState<GridItemInterface[]>([]);
  const itemPerPage = isMobile ? 6 : 9;

  useEffect(() => {
    setPage(1);
    setItemList([]);
  }, [subSubKey]);

  const [isMediaOpened, setIsMediaOpened] = useState(false);
  const [openedMediaUrl, setOpenedMediaUrl] = useState('');

  const { isLoading, isError, data = {}, isFetching } = useQuery(
    ['mediaLibraries', subSubKey, languageId, page],
    () =>
      apiAxios({
        method: 'GET',
        uri: `/media/libraries?submenu=${subSubKey}&language_id=${languageId}&limit=${itemPerPage}&page=${page}`
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data.items) {
      if (page === 1) {
        setItemList(data.items);
      } else {
        if (data.items.length > 0) setItemList(prevItemList => prevItemList.concat(data.items));
      }
    }
    setTotal(data.total);
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  const handleClickMedia = (value: string) => {
    setOpenedMediaUrl(value);
    setIsMediaOpened(true);
  };

  const handleMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <>
      <Wrapper>
        <Grid>
          {itemList.map((item: GridItemInterface, index: number) => {
            const videoId = subSubKey === 'video' ? getYoutubeId(item.video_url) : '';
            const thumbUrl = subSubKey === 'video' ? getYoutubeThumbImageUrl(item.video_url) : item.thumbnail_url; // TODO: 비디오타입도 thumbnail_url 사용하도록 수정
            const mediaUrl = videoId || item.thumbnail_url;
            return (
              <Card key={index} className="card" imageUrl={encodeFileUrl(thumbUrl)}>
                <div className="thumbnail" onClick={() => handleClickMedia(mediaUrl)} />
                <h3>
                  <button onClick={() => handleClickMedia(mediaUrl)}>{item.title_t}</button>
                </h3>
                <span className="datetime">{dateHelper(item.datetime, '-')}</span>
              </Card>
            );
          })}
        </Grid>
        {!isFetching && total > itemList.length && <MoreButton onClickHandler={handleMore} />}
      </Wrapper>
      {isMediaOpened && (
        <MediaDetailWrapper submenu={subSubKey}>
          <div className="content">
            <header>
              {/* <button className="download">
                {t('download')}
                <img src={DownloadIcon} alt="download" />
              </button> */}
              <button className="close" onClick={() => setIsMediaOpened(false)}>
                <img src={CloseIcon} alt="download" />
              </button>
            </header>
            <div className="fileContent">
              {subSubKey === 'video' ? (
                <iframe
                  width="100%"
                  src={`https://www.youtube.com/embed/${openedMediaUrl}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              ) : (
                <img src={openedMediaUrl} alt="media" />
              )}
            </div>
          </div>
        </MediaDetailWrapper>
      )}
    </>
  );
};

export default Library;
