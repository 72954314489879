import { useEffect, useRef, useState } from 'react';
import { dropdownAnimation } from '../../assets/styles';
import DropDownIcon from '../../assets/images/DownArrow.png';
import UpArrowIcon from '../../assets/images/UpArrow.png';
import styled from 'styled-components';

interface DropDownItemProps {
  name: string;
  children?: string[];
  handleClick?: any;
}

interface ParentWrapperProps {
  isDisplayChildren: boolean;
}

const ParentWrapper = styled.div<ParentWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid ${({ isDisplayChildren }) => (isDisplayChildren ? '#1a1a1a' : '#ffffff')};
  transition: border-bottom ease-in-out 150ms;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ChildrenItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px 32px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #999999;

  span.subLink {
    font-family: 'Noto Sans KR';
    line-height: 100%;
  }

  span.subLink:first-child {
    margin-top: 0px;
  }

  span.subSubLink {
    font-weight: normal;
    margin-top: 24px;
  }

  span:hover {
    cursor: pointer;
  }
`;

const ChildrenWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  background: white;
  z-index: 998;
  border-bottom: 1px solid #ece6e2;

  ${ChildrenItem}:last-child {
    margin-bottom: 32px;
  }

  ${dropdownAnimation('0ms')}
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .title {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    text-decoration: none;
  }
`;

const StringDropDownItem = ({ name, children = [], handleClick }: DropDownItemProps) => {
  // const { t } = useTranslation();
  const [isDisplayChildren, setIsDisplayChildren] = useState(false);
  const outsideRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClickChildren = (item: string) => {
    if (handleClick) {
      handleClick(item);
    }

    setIsDisplayChildren(false);
  };

  useEffect(() => {
    function handleClickOutside(event: React.BaseSyntheticEvent | MouseEvent) {
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        setIsDisplayChildren(false);
      }
    }
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [outsideRef]);

  return (
    <Wrapper ref={outsideRef}>
      <ParentWrapper
        className="parentWrapper"
        isDisplayChildren={isDisplayChildren}
        onClick={() => setIsDisplayChildren(!isDisplayChildren)}
      >
        <span className="title">{name}</span>
        {isDisplayChildren ? <img src={UpArrowIcon} alt="up" /> : <img src={DropDownIcon} alt="down" />}
      </ParentWrapper>
      <ChildrenWrapper className={`childrenWrapper ${isDisplayChildren ? 'up' : 'down'}`}>
        {children.map((item, index) => {
          return (
            <ChildrenItem key={index}>
              <span className="subLink" onClick={() => handleClickChildren(item)}>
                {item}
              </span>
            </ChildrenItem>
          );
        })}
      </ChildrenWrapper>
    </Wrapper>
  );
};

export default StringDropDownItem;
