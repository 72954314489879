import { css } from 'styled-components';

const fadeAnimation = css`
  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 400ms, opacity 200ms;
  }

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 200ms;
  }
`;

const dropdownAnimation = (speed = '200ms') => css`
  transition: max-height ${speed} ease-in-out;

  &.down {
    max-height: 0px;
  }

  &.up {
    max-height: 1000px;
  }
`;

export { fadeAnimation, dropdownAnimation };
