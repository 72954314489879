import { useLocation } from 'react-router-dom';

const Redirect = () => {
  const location = useLocation();

  const { path } = location?.state ? (location?.state as { path: string | null }) : { path: '' };
  window.location.href = (process.env.REACT_APP_IR_ORIGIN_NAME || window.location.href) + (path && path[0] !== '/' ? '/' : '') + path;

  return null;
};

export default Redirect;
