import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useScrollToTop } from './context/router';

import Nav from './components/Nav';
import Home from './container/Home';
import NotFound from './container/NotFound';
import styled from 'styled-components';
import Footer from './components/Footer';
import BottomNav from './components/Nav/BottomNav';
import CeoMessage from './container/Company/CeoMessage';
import Banner from './components/Layout/Banner';
import Introduction from './container/Company/Introduction';
import History from './container/Company/History';
import SustainableManagement from './container/Company/SustainableManagement';
import SubNav from './components/Nav/SubNav';
import AuditReport from './container/Finance/AuditReport';
import Summary from './container/Finance/Summary';
import Chart from './container/Stock/Chart';
import OwnerStructure from './container/Stock/OwnerStructure';
import Schedule from './container/IR/Schedule';
import Earning from './container/IR/Earning';
import Disclosure from './container/IR/Disclosure';
import Report from './container/IR/Report';
import NewsRoom from './container/Media/NewsRoom';
import Library from './container/Media/Library';
import ReportDetail from './container/IR/ReportDetail';
import NewsRoomDetail from './container/Media/NewsRoomDetail';
import SustainableManagementDetail from './container/Company/SustainableManagementDetail';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import GovernanceDirector from './container/Company/GovernanceDirector';
import GovernanceCommittee from './container/Company/GovernanceCommittee';
import GovernanceShareholder from './container/Company/GovernanceShareholder';
import Redirect from './container/Redirect';
import { IS_NEWS_URL } from './helpers/constants';

const queryClient = new QueryClient();

const Main = styled.main`
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  max-width: 1320px;
  margin: 0 auto;
`;

function App() {
  useScrollToTop();

  return (
    <QueryClientProvider client={queryClient}>
      <Nav />
      <Banner />
      <Main>
        <Content>
          <Routes>
            {IS_NEWS_URL ? (
              <>
                <Route path="/" element={<SubNav />}>
                  <Route path="/" element={<Report />} />
                  <Route path="/ir/report/:id" element={<ReportDetail />} />
                </Route>
                <Route path="/redirect" element={<Redirect />} />
                <Route path="/error.html" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="/company" element={<SubNav />}>
                  <Route path="ceo-message" element={<CeoMessage />} />
                  <Route path="introduction" element={<Introduction />} />
                  <Route path="history" element={<History />} />
                  <Route path="governance">
                    <Route index element={<Navigate to="directors" />} />
                    <Route path="directors" element={<GovernanceDirector />} />
                    <Route path="committee" element={<GovernanceCommittee />} />
                    <Route path="shareholders" element={<GovernanceShareholder />} />
                    <Route path="*" element={<Navigate to="directors" />} />
                  </Route>
                  <Route path="sustainable-management">
                    <Route index element={<Navigate to="overview" />} />
                    <Route path="overview" element={<SustainableManagement />} />
                    <Route path="partnership" element={<SustainableManagement />} />
                    <Route path="partnership/:id" element={<SustainableManagementDetail />} />
                    <Route path="environment" element={<SustainableManagement />} />
                    <Route path="social" element={<SustainableManagement />} />
                    <Route path="*" element={<Navigate to="overview" />} />
                  </Route>
                </Route>
                <Route path="/finance" element={<SubNav />}>
                  <Route path="summary">
                    <Route index element={<Navigate to="consolidated" />} />
                    <Route path="consolidated" element={<Summary />} />
                    <Route path="non-consolidated" element={<Summary />} />
                    <Route path="*" element={<Navigate to="consolidated" />} />
                  </Route>
                  <Route path="audit-report" element={<AuditReport />} />
                </Route>
                {/* <Route path="/stock" element={<SubNav />}>
                  <Route path="chart" element={<Chart />} />
                  <Route path="owner-structure" element={<OwnerStructure />} />
                </Route> */}
                <Route path="/ir" element={<SubNav />}>
                  {/* <Route path="schedule" element={<Schedule />} /> */}
                  <Route path="earning" element={<Earning />} />
                  <Route path="disclosure" element={<Disclosure />} />
                  <Route path="report" element={<Report />} />
                  <Route path="report/:id" element={<ReportDetail />} />
                </Route>
                <Route path="/media" element={<SubNav />}>
                  <Route path="news-room">
                    <Route index element={<Navigate to="press" />} />
                    <Route path="press" element={<NewsRoom />} />
                    <Route path="article" element={<NewsRoom />} />
                    <Route path="notice" element={<NewsRoom />} />
                    <Route path="press/:id" element={<NewsRoomDetail />} />
                    <Route path="article/:id" element={<NewsRoomDetail />} />
                    <Route path="notice/:id" element={<NewsRoomDetail />} />
                    <Route path="*" element={<Navigate to="press" />} />
                  </Route>
                  <Route path="library">
                    <Route index element={<Navigate to="video" />} />
                    <Route path="video" element={<Library />} />
                    <Route path="image" element={<Library />} />
                    <Route path="*" element={<Navigate to="video" />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </>
            )}
          </Routes>
        </Content>
      </Main>
      <BottomNav />
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
