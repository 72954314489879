interface MenuInterface {
  // 인덱스 시그니처
  [prop: string]: any;
}

const IS_NEWS_URL = window?.location?.origin === process.env.REACT_APP_NEWS_ORIGIN_NAME;

// 영문 변환 시 숨겨지는 메뉴 리스트
const EN_HIDDEN_LIST = ['disclosure', 'report'];

// INACTIVE 메뉴 리스트
const INACTIVE_LIST = ['chart'];

const MENU: MenuInterface = Object.freeze({
  home: {
    name: '홈',
    bannerImageName: '',
    subMenuList: []
  },
  company: {
    name: '회사정보',
    bannerImageName: 'companyBanner',
    subMenuList: [
      {
        name: '회사 소개',
        to: 'introduction'
      },
      {
        name: 'CEO 메세지',
        to: 'ceo-message'
      },
      {
        name: '연혁 및 수상',
        to: 'history'
      },
      {
        name: '기업지배구조',
        to: 'governance',
        subSubMenuList: [
          {
            name: '이사회',
            key: 'directors'
          },
          {
            name: '위원회',
            key: 'committee'
          },
          {
            name: '주주총회',
            key: 'shareholders'
          }
        ]
      },
      {
        name: '지속가능경영',
        to: 'sustainable-management',
        subSubMenuList: [
          {
            name: '개요',
            key: 'overview'
          },
          {
            name: '동반 성장',
            key: 'partnership'
          },
          {
            name: '환경',
            key: 'environment'
          },
          {
            name: '사회 기여',
            key: 'social'
          }
        ]
      }
    ]
  },
  finance: {
    name: '재무정보',
    bannerImageName: 'financeBanner',
    subMenuList: [
      {
        name: '요약재무정보',
        to: 'summary',
        subSubMenuList: [
          {
            name: '연결재무제표',
            key: 'consolidated'
          },
          {
            name: '별도재무제표',
            key: 'non-consolidated'
          }
        ]
      },
      {
        name: '감사보고서',
        to: 'audit-report'
      }
    ]
  },
  stock: {
    name: '주식정보',
    bannerImageName: 'stockBanner',
    subMenuList: [
      {
        name: '주가정보',
        to: 'chart'
      },
      {
        name: '주주구성',
        to: 'owner-structure'
      }
    ]
  },
  ir: {
    name: 'IR 정보',
    bannerImageName: 'irBanner',
    subMenuList: [
      {
        name: 'IR 자료',
        to: 'earning'
      },
      // {
      //   name: 'IR 일정',
      //   to: 'schedule'
      // },
      {
        name: '공시',
        to: 'disclosure'
      },
      {
        name: '공고',
        to: 'report'
      }
    ]
  },
  media: {
    name: '미디어센터',
    bannerImageName: 'mediaBanner',
    subMenuList: [
      {
        name: '뉴스룸',
        to: 'news-room',
        subSubMenuList: [
          {
            name: '보도자료',
            key: 'press'
          },
          {
            name: '언론기사',
            key: 'article'
          },
          {
            name: '알려드립니다',
            key: 'notice'
          }
        ]
      },
      {
        name: '미디어 라이브러리',
        to: 'library',
        subSubMenuList: [
          {
            name: '영상',
            key: 'video'
          },
          {
            name: '이미지',
            key: 'image'
          }
        ]
      }
    ]
  }
});

const KOREAN_ORDER_ARRAY = ['가', '나', '다', '라', '마', '바', '사', '아', '자', '차', '카', '타', '파', '하'];
const ENGLISH_ORDER_ARRAY = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export { MENU, EN_HIDDEN_LIST, INACTIVE_LIST, IS_NEWS_URL, KOREAN_ORDER_ARRAY, ENGLISH_ORDER_ARRAY };
